import FavoriteAddressService from '../interfaces/services/FavoriteAddressService';
import StorageService from '../interfaces/services/StorageService';
import Address from '../interfaces/entities/Address';

export default class StorageFavoriteAddressService
  implements FavoriteAddressService
{
  private storage: StorageService;
  constructor(storage: StorageService) {
    this.storage = storage;
  }

  /**
   * Adds an address to the list of favorite addresses.
   *
   * @param address - The address to add.
   */
  async add(address: Address): Promise<void> {
    const addresses = await this.getAll();
    addresses.push(address);
    await this.storage.set('favorite_address', JSON.stringify(addresses));
  }

  /**
   * Retrieves all addresses from storage.
   *
   * @returns An array of addresses, or an empty array if no addresses are found.
   */
  async getAll(): Promise<Address[]> {
    const storageResult = await this.storage.get('favorite_address');
    if (storageResult) {
      const addresses = JSON.parse(storageResult) as Address[];
      return addresses;
    } else {
      return [];
    }
  }

  /**
   * Removes an address from the list of favorite addresses.
   *
   * @param address - The address to remove.
   */
  async remove(address: Address): Promise<void> {
    const addresses = await this.getAll();
    addresses.splice(addresses.indexOf(address), 1);
    await this.storage.set('favorite_address', JSON.stringify(addresses));
  }
}
