import TripHistory from '../../interfaces/entities/TripHistory';
import Header from '../../components/Header';
import StarComponent from '../../components/StarComponent';
import displayAddressesBaseOnBookingType from '../../helpers/displayAddressesBaseOnBookingType';
import renderMoney from '../../helpers/renderMoney';

export default function DetailHistoryPage(props: { trip: TripHistory }) {
  const { trip } = props;
  const displayAddresses = displayAddressesBaseOnBookingType(trip.type);
  return (
    <div className={'page'}>
      <Header title={'Chi tiết'} />
      <div
        className={'flex-column center'}
        style={{
          padding: '25px 0',
          gap: 20,
        }}
      >
        <p className={'bold h5'}>Đánh giá dịch vụ</p>
        <StarComponent star={trip.rate} size={'large'} />
      </div>

      <div className={'horizontal-light-separator'} />

      <div
        className={'flex-row align-center'}
        style={{ gap: 20, padding: '20px 30px' }}
      >
        <div
          className={'round main-border center'}
          style={{
            height: 40,
            width: 40,
          }}
        >
          <img
            src={trip.image}
            alt={trip.code}
            className={'contain round'}
            style={{ height: 40, width: 40 }}
          />
        </div>

        <div className={'flex-column flex-1'} style={{ gap: 8 }}>
          <p className={'bold h5'}>{trip.name}</p>
          <div className={'flex-row'}>
            <p className={'flex-1 bold h5'}>Mã: {trip.code}</p>
            <p className={'gray-text h5'}>{trip.date}</p>
          </div>
        </div>
      </div>

      <div className={'horizontal-light-separator'} />

      <div className={'flex-column'} style={{ padding: '20px 30px', gap: 15 }}>
        {displayAddresses.pickup && (
          <div className={'flex-row'} style={{ gap: 15 }}>
            <div
              className={'flex-column center'}
              style={{ height: 15, width: 15 }}
            >
              <div
                className={'address-point background-green'}
                style={{ marginTop: 2 }}
              />
            </div>

            <div className={'flex-column'} style={{ gap: 10 }}>
              <p className={'h5'}>Điểm đi</p>

              <div data-testid="pickup" className={'bold h5 full-width'}>
                {props.trip.pickup}
              </div>
            </div>
          </div>
        )}
        {displayAddresses.dropoff1 && (
          <div className={'flex-row'} style={{ gap: 15 }}>
            <div
              className={'flex-column center'}
              style={{ height: 15, width: 15 }}
            >
              <div
                className={'address-point background-main'}
                style={{ marginTop: 2 }}
              />
            </div>

            <div className={'flex-column'} style={{ gap: 10 }}>
              <p className={'h5'}>Điểm đến 1</p>

              <div data-testid="dropoff1" className={'bold h5 full-width'}>
                {props.trip.dropoff1}
              </div>
            </div>
          </div>
        )}
        {displayAddresses.dropoff2 && trip.dropoff2 && (
          <div className={'flex-row'} style={{ gap: 15 }}>
            <div
              className={'flex-column center'}
              style={{ height: 15, width: 15 }}
            >
              <div
                className={'address-point background-main-2'}
                style={{ marginTop: 2 }}
              />
            </div>

            <div className={'flex-column'} style={{ gap: 10 }}>
              <p className={'h5'}>Điểm đến 2</p>

              <div data-testid="dropoff2" className={'bold h5 full-width'}>
                {props.trip.dropoff2}
              </div>
            </div>
          </div>
        )}
        {displayAddresses.startDay && (
          <div className={'flex-column'} style={{ gap: 10 }}>
            <p className={'h5'}>Ngày đi</p>

            <div data-testid="startday" className={'bold h5 full-width'}>
              {props.trip.startDay}
            </div>
          </div>
        )}
        {displayAddresses.endDay && (
          <div className={'flex-column'} style={{ gap: 10 }}>
            <p className={'h5'}>Ngày đi</p>

            <div data-testid="enddate" className={'bold h5 full-width'}>
              {props.trip.endDay}
            </div>
          </div>
        )}
      </div>

      <div className={'horizontal-light-separator'} />

      <div className={'flex-column'} style={{ padding: '20px 30px', gap: 8 }}>
        <div className={'flex-row align-center'}>
          <p className={'flex-1 h5'}>Tổng tiền:</p>
          <p className={'bold h5'}>{renderMoney(trip.totalCost)}</p>
        </div>
        <div className={'flex-row align-center'}>
          <p className={'flex-1 h5'}>Tiền bảo hiểm:</p>
          <p className={'bold h5'}>{renderMoney(trip.insuranceCost)}</p>
        </div>
        <div className={'flex-row align-center'}>
          <p className={'flex-1 h5'}>Khuyến mãi:</p>
          <p className={'bold h5'}>{renderMoney(trip.discount)}</p>
        </div>
        <div className={'flex-row align-center'}>
          <p className={'flex-1 h5'}>Khách trả:</p>
          <p className={'bold h5 main-text'}>
            {renderMoney(trip.totalPayment)}
          </p>
        </div>
      </div>
    </div>
  );
}
