import { useEffect, useRef, useState } from 'react';
import {
  useAlertService,
  useDataService,
} from '../../providers/ServicesProvider';
import TripHistory from '../../interfaces/entities/TripHistory';
import FullPageLoading from '../../components/FullPageLoading';
import InfiniteScroll from 'react-infinite-scroll-component';
import HistoryRow from '../../components/HistoryRow';
import Header from '../../components/Header';

export default function HistoryPage() {
  const dataService = useDataService();
  const alertService = useAlertService();
  const [loading, setLoading] = useState(false);
  const page = useRef(1);
  const [hasMore, setHasMore] = useState(true);
  const [histories, setHistories] = useState<TripHistory[]>([]);

  const fetchHistory = async () => {
    try {
      setLoading(true);
      const response = await dataService.getHistory(page.current);
      page.current = page.current + 1;
      if (response.length < 10) {
        setHasMore(false);
      }
      setHistories([...histories, ...response]);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      // alertService.error(e.message);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div className={'flex-1 flex-column default-padding'}>
      {loading && page.current === 1 && (
        <div data-testid={'full-page-loading'} />
      )}
      <InfiniteScroll
        data-testid={'history-list'}
        dataLength={histories.length}
        next={fetchHistory}
        hasMore={hasMore}
        loader={
          <p
            className={'text-center gray-text full-width'}
            style={{ margin: '10px 0px' }}
          >
            Lấy thêm dữ liệu ...
          </p>
        }
        endMessage={
          <p
            className={'text-center gray-text full-width'}
            style={{ margin: '10px 0px' }}
          >
            Không còn dữ liệu
          </p>
        }
      >
        {histories.length === 0 && <div>Chưa có dữ liệu</div>}
        {histories.map(history => (
          <div style={{ padding: '8px 0' }} key={history.id}>
            <HistoryRow key={history.id} trip={history} />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
}
