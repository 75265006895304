export interface GetprofileResponse {
  profile: Profile;
}

export interface Profile {
  phone: string;
  email: string;
  name: string;
  deviceToken: any;
  point: any;
  title: any;
  avatar_img: any;
  rating: number;
  gplx_level: any;
  gplx_image: any;
  gplx_image_s: any;
  gplx_number: any;
  cmnd: any;
  cmnd_image: any;
  cmnd_image_s: any;
  exp: number;
  experience: any;
  money: number;
}

export function createProfileFromResponse(response: GetprofileResponse) {
  return {
    phone: response.profile.phone,
    email: response.profile.email,
    name: response.profile.name,
    avatar: response.profile.avatar_img || '',
  };
}
