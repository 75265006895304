import {
  useAlertService,
  useDataService,
} from '../../providers/ServicesProvider';
import { useEffect, useState } from 'react';
import ServiceGroup from '../../interfaces/entities/ServiceGroup';
import Images from '../../assets/images';
import ServiceGroupComponent from '../../components/ServiceGroupComponent';

export default function FrontPage() {
  const dataService = useDataService();
  const alertService = useAlertService();
  const [serviceGroups, setServiceGroups] = useState<ServiceGroup[]>([]);

  const fetcData = async () => {
    try {
      const result = await dataService.getServiceGroups();
      setServiceGroups(result);
    } catch (err: any) {
      alertService.error(err.message);
    }
  };

  useEffect(() => {
    fetcData();
  }, []);

  return (
    <div className={'flex-1 flex-column'}>
      <div
        className={
          'flex-column center default-padding background-main sticky-top'
        }
      >
        <img
          alt={''}
          className={'contain'}
          src={Images.logo}
          style={{ height: 96, width: 96 }}
        />
        <p className={'white-text text-center h7'} style={{ marginTop: -20 }}>
          Hãy cứ là bạn, an toàn đã có tôi
        </p>
      </div>
      <div
        className={'flex-column horizontal-padding'}
        style={{ marginTop: -10, gap: 10 }}
      >
        {serviceGroups.map(serviceGroup => (
          <ServiceGroupComponent serviceGroup={serviceGroup} />
        ))}
      </div>
    </div>
  );
}
