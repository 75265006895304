import Texts from '../../text/texts';
import React, { useRef, useState } from 'react';
import validatePhoneNumber from '../../helpers/validatePhoneNumber';
import {
  useAlertService,
  useAuthenService,
  useDataService,
  useNavigationService,
} from '../../providers/ServicesProvider';
import OTPInput from 'react-otp-input';
import Images from '../../assets/images';
import IconTextInput from '../../components/IconTextInput';
import Header from '../../components/Header';

export const OTP_LENGTH = 4;
export const TIMEOUT_MS = 30;

function OTPComponent(props: {
  value: string;
  onChange: (value: ((prevState: string) => string) | string) => void;
}) {
  return (
    <div className={'flex-column full-width'} style={{ marginTop: 12 }}>
      <div className={'flex-row'} style={{ gap: 12 }}>
        <img
          src={Images.key}
          style={{ height: 24, width: 24 }}
          alt={'otp-icon'}
        />
        <div className={'flex-column'} style={{ gap: 12 }}>
          <p className={'h4 gray-text'}>Mã đăng nhập</p>

          <OTPInput
            data-testid={'otp-input'}
            value={props.value}
            onChange={props.onChange}
            numInputs={OTP_LENGTH}
            renderSeparator={<span style={{ width: '10%' }} />}
            inputType={'number'}
            renderInput={(props, index) => {
              return (
                <input
                  {...props}
                  className={'bold h0 input border-bottom text-center'}
                  style={{ height: 50, width: '20%' }}
                  data-testid={`otp-input-${index + 1}`}
                  type={'number'}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default function LoginPage() {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [countdown, setCountdown] = useState(TIMEOUT_MS);
  const [countDownRunning, setCountDownRunning] = useState(false);
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const countdownRef = useRef(TIMEOUT_MS);
  const [error, setError] = useState<string | null>(null);
  const [loginWithOTP, setLoginWithOTP] = useState(false);
  const dataService = useDataService();
  const navigationService = useNavigationService();
  const authenService = useAuthenService();
  const alertService = useAlertService();

  countdownRef.current = countdown;

  const submit = async () => {
    try {
      const validatePhoneNumberResult = validatePhoneNumber(phone);
      if (!validatePhoneNumberResult.result) {
        const code = validatePhoneNumberResult.code;
        switch (code) {
          case 'EMPTY':
            setError(Texts.loginScreen.errors.nullPhone);
            break;
          case 'INVALID':
            setError(Texts.loginScreen.errors.invalidPhone);
            break;
          default:
            break;
        }
        return;
      }

      if (!loginWithOTP && password.length === 0) {
        setError(Texts.loginScreen.errors.nullPassword);
        return;
      }

      if (loginWithOTP && otp.length < OTP_LENGTH) {
        setError(Texts.loginScreen.errors.nullOTP);
        return;
      }

      setError(null);
      if (loginWithOTP) {
        const verifyResult = await dataService.verifyOTP(phone, otp);
        if (verifyResult.result) {
          await authenService.saveAuthen(verifyResult.token);
          await authenService.savePhone(phone);

          const nextScreen = verifyResult.firstTimeLogin ? '/update-info' : '/';
          navigationService.navigate(nextScreen, null);
        } else {
          setError(Texts.loginScreen.errors.wrongOTP);
        }
      } else {
        const { token } = await dataService.loginWithPassword(phone, password);

        if (token) {
          await authenService.saveAuthen(token);
          await authenService.savePhone(phone);

          navigationService.navigate('/', null);
        } else {
          setError(Texts.loginScreen.errors.wrongUsernamePassword);
        }
      }
    } catch (error: any) {
      alertService.error(error.message);
    }
  };

  const toggleLoginWithOTP = () => setLoginWithOTP(!loginWithOTP);

  const requestOTP = async () => {
    try {
      if (phone.length === 0) {
        setError(Texts.loginScreen.errors.nullPhone);
        return;
      }
      startCountdown();
      await dataService.requestOTP(phone);
    } catch (error: any) {
      alertService.error(error.message);
    }
  };

  const startCountdown = () => {
    setCountDownRunning(true);
    setCountdown(TIMEOUT_MS);

    intervalRef.current = setInterval(() => {
      if (countdownRef.current === 0 && intervalRef.current) {
        clearInterval(intervalRef.current);
        setCountDownRunning(false);
      } else {
        setCountdown(countdownRef.current - 1);
      }
    }, 1000);
  };

  return (
    <div className={'page'}>
      <Header title={Texts.loginScreen.title} />

      <div
        className={'flex-column flex-1 center default-padding'}
        style={{ gap: 15 }}
      >
        <div className={'flex-row full-width'} style={{ gap: 12 }}>
          <IconTextInput
            icon={'phone'}
            placeholder={Texts.loginScreen.phoneNumberInput}
            value={phone}
            onChangeText={text => setPhone(text)}
            type={'tel'}
          />
          {loginWithOTP && (
            <button
              className={`button bold h6 ${countDownRunning ? 'disabled' : null}`}
              onClick={requestOTP}
              style={{ width: 45 }}
              disabled={countDownRunning}
            >
              {countDownRunning ? countdown : Texts.loginScreen.getOTPButton}
            </button>
          )}
        </div>
        {loginWithOTP ? (
          <OTPComponent value={otp} onChange={setOtp} />
        ) : (
          <IconTextInput
            icon={'key'}
            placeholder={Texts.loginScreen.passwordInput}
            value={password}
            type={'password'}
            onChangeText={text => setPassword(text)}
          />
        )}
        {error && <span className={'error-text h5'}>{error}</span>}
        <button className={'button full-width bold h4'} onClick={submit}>
          {Texts.loginScreen.loginButton}
        </button>
        <button
          onClick={toggleLoginWithOTP}
          className={'button bold full-width h4'}
        >
          {loginWithOTP
            ? Texts.loginScreen.loginWithPassword
            : Texts.loginScreen.loginWithOTPButton}
        </button>
      </div>
    </div>
  );
}
