import Lottie from 'react-lottie';
import { useNavigationService } from '../../providers/ServicesProvider';
import Animations from '../../assets/animations';

export default function RejectTripPage() {
  const navigationService = useNavigationService();
  const onClickOK = () => {
    navigationService.replace('/', null);
  };
  return (
    <div className={'page align-center'}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: Animations.cancel,
        }}
        style={{
          marginTop: 24,
        }}
        height={'calc(100vw - 20px)'}
        width={'calc(100vw - 20px)'}
      />
      <div
        className="flex-column align-center"
        style={{ gap: 4, marginTop: 24 }}
      >
        <p className="bold h3">THÔNG BÁO</p>
        <p className="h4">Chuyến đi đã bị hủy</p>
      </div>
      <button
        data-testid={'ok-button'}
        onClick={onClickOK}
        className="button-2 h4"
        style={{
          marginTop: 24,
          width: '30%',
        }}
      >
        Xác nhận
      </button>
    </div>
  );
}
