export default interface DoEstimateResponse {
  res: {
    duration: number;
    durationText: string;
    originalCost: number;
    cost: number;
    cost_estimate_original: number;
    discountCost: number;
    service_cost: number;
    mapData: string;
    time: number;
    distance: number;
    distanceText: string;
  };
}

export function getEstimatedCost(response: DoEstimateResponse): number {
  return response.res.cost;
}
