import React, { useEffect, useState } from 'react';
import {
  useAlertService,
  useDataService,
  useLocationService,
  useNavigationService,
} from '../../providers/ServicesProvider';
import FullPageLoading from '../../components/FullPageLoading';
import AddressPicker from '../../components/AddressPicker';
import Images from '../../assets/images';
import ServicePanel from '../../components/ServicePanel';
import Map from '../../components/Map';
import { useDispatch, useSelector } from 'react-redux';
import {
  bookingAction,
  selectCost,
  selectDropOffAddress1,
  selectDropOffAddress2,
  selectEndDay,
  selectNote,
  selectPaymentType,
  selectPickupAddress,
  selectPromotion,
  selectSelectedServiceType,
  selectService,
  selectStartDay,
} from '../../redux/slices/bookingSlice';
import hasEnoughDataForBooking from '../../helpers/hasEnoughDataForBooking';

export default function BookPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const service = useSelector(selectService);
  const selectedServiceType = useSelector(selectSelectedServiceType);
  const pickupAddress = useSelector(selectPickupAddress);
  const dropOffAddress1 = useSelector(selectDropOffAddress1);
  const dropOffAddress2 = useSelector(selectDropOffAddress2);
  const paymentType = useSelector(selectPaymentType);
  const promotion = useSelector(selectPromotion);
  const note = useSelector(selectNote);
  const cost = useSelector(selectCost);
  const startDate = useSelector(selectStartDay);
  const endDate = useSelector(selectEndDay);

  const [displayControl, setDisplayControl] = useState(true);

  const locationService = useLocationService();
  const dataService = useDataService();
  const navigationService = useNavigationService();
  const alertService = useAlertService();

  const fetchPickupAddress = async () => {
    try {
      const response = await locationService.getCurrentAddress();
      if (response) dispatch(bookingAction.setPickupAddress(response));
      setLoading(false);
    } catch (e: any) {
      alertService.warning(e.message);
      setLoading(false);
    }
  };

  const estimateCost = async () => {
    if (!selectedServiceType) return;

    if (
      pickupAddress &&
      hasEnoughDataForBooking(
        pickupAddress,
        dropOffAddress1,
        selectedServiceType,
      )
    ) {
      const cost = await dataService.estimateCost({
        pickup: pickupAddress,
        dropOff_1: dropOffAddress1,
        dropOff_2: dropOffAddress2,
        serviceType: selectedServiceType,
        paymentType: paymentType,
        startDate: startDate,
        endDate: endDate,
        ...(promotion.length > 0 ? { promotion: promotion } : {}),
      });

      if (promotion) {
        const costAfterDiscount = await dataService.applyDiscount(
          cost,
          promotion,
        );
        if (costAfterDiscount !== cost) {
          dispatch(bookingAction.setCost(costAfterDiscount));
          dispatch(bookingAction.setOldCost(cost));
        } else {
          dispatch(bookingAction.setCost(cost));
          dispatch(bookingAction.setOldCost(null));
        }
      } else {
        dispatch(bookingAction.setCost(cost));
        dispatch(bookingAction.setOldCost(null));
      }
    }
  };

  const onSubmit = async () => {
    if (!service) return;
    if (!dropOffAddress1 || !pickupAddress) return;
    if (!selectedServiceType) return;

    try {
      const tripID = await dataService.book({
        pickup: pickupAddress,
        dropOff_1: dropOffAddress1,
        dropOff_2: dropOffAddress2,
        serviceType: selectedServiceType,
        paymentType: paymentType,
        promotion: promotion,
        note: note,
      });
      dispatch(bookingAction.setBookID(tripID));
      navigationService.navigate('/find-driver', null);
    } catch (error: any) {
      alertService.error(error.message);
    }
  };

  useEffect(() => {
    // if pickup address is not existed => then fetchit
    if (!pickupAddress) {
      fetchPickupAddress();
    } else {
      setLoading(false);
    }

    // fetch service types
    if (service && selectedServiceType === null) {
      dataService
        .getServiceTypes(service)
        .then(types => {
          dispatch(bookingAction.setServiceTypes(types));
          // set first type as selected
          if (types.length > 0) {
            dispatch(bookingAction.setSelectedServiceType(types[0]));
          }
        })
        .catch(error => alertService.error(error.message));
    }
  }, []);

  // recalculate cost when addresses changed
  useEffect(() => {
    estimateCost();
  }, [
    pickupAddress,
    dropOffAddress1,
    dropOffAddress2,
    startDate,
    endDate,
    promotion,
    selectedServiceType,
  ]);

  if (loading || !service) return <FullPageLoading />;

  return (
    <div className={'page'}>
      <div style={{ height: '90vh', width: '100%' }} className={'layer-1'}>
        {pickupAddress ? (
          <Map
            pickupAddress={pickupAddress}
            dropOffAddress1={dropOffAddress1}
            dropOffAddress2={dropOffAddress2}
            onDragStart={() => setDisplayControl(false)}
            onDragEnd={() => setDisplayControl(true)}
          />
        ) : (
          <div className={'flex-column center full-height background-gray'}>
            <p className={'h5 gray-text'}>
              Vui lòng chọn điểm đón để hiển thị bản đồ
            </p>
          </div>
        )}
      </div>

      <div className={'absolute-top layer-3'} style={{ marginTop: 15 }}>
        <img
          style={{ height: 16, width: 16, left: 26 }}
          className={'icon-button absolute-left'}
          src={Images.back}
          alt={''}
          data-testid={'back-button'}
          onClick={() => navigationService.back()}
        />
      </div>

      {displayControl && <AddressPicker />}

      {displayControl && <ServicePanel onSubmit={onSubmit} />}
    </div>
  );
}
