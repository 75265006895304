import TripDetail from '../../interfaces/entities/TripDetail';
import {
  useAlertService,
  useDataService,
  useNavigationService,
  useTripService,
} from '../../providers/ServicesProvider';
import { useEffect, useState } from 'react';
import FullPageLoading from '../../components/FullPageLoading';
import renderMoney from '../../helpers/renderMoney';
import HotlineFloatingButton from '../../components/HotlineFloatingButton';
import Images from '../../assets/images';
import { useDispatch } from 'react-redux';
import { bookingAction } from '../../redux/slices/bookingSlice';

export default function TripPage(props: { tripID: string }) {
  // get tripID
  const { tripID } = props;
  const [tripDetail, setTripDetail] = useState<TripDetail | null>(null);
  const dataService = useDataService();
  const alertService = useAlertService();
  const navigationService = useNavigationService();
  const tripService = useTripService();
  const dispatch = useDispatch();

  const fetchTripDetail = async () => {
    try {
      const tripDetail = await dataService.getTripDetail(tripID);
      setTripDetail(tripDetail);
      dispatch(bookingAction.setDriver(tripDetail.driver));
    } catch (e: any) {
      alertService.error(e.message);
    }
  };

  // handle user click on message icon
  const handleUserClickOnMessageIcon = () => {
    if (!tripDetail) return;

    // navigate to chat page with tripID and driver
    navigationService.navigate('/chat', {
      tripID: tripDetail.tripID,
      driver: tripDetail.driver,
    });
  };

  const handleUserClickOnMap = () => {
    // navigate to map page with tripID
    navigationService.navigate('/follow-trip-map', {
      tripID: tripID,
    });
  };

  const onFinishTrip = () => {
    // navigate to rating page with tripID
    navigationService.navigate('/rating', {
      tripID: tripID,
    });
  };

  const onRejectTrip = () => {
    // navigate to rating page with tripID
    navigationService.navigate('/reject', {
      tripID: tripID,
    });
  };

  // fetch data on default
  useEffect(() => {
    fetchTripDetail();
  }, []);

  // listenning to event
  useEffect(() => {
    const onFinishTripSub = tripService.onFinishTrip(onFinishTrip);
    const onRejectTripSub = tripService.onRejectTrip(onRejectTrip);

    return () => {
      onFinishTripSub.remove();
      onRejectTripSub.remove();
    };
  }, []);

  // display loading if trip is empty
  if (!tripDetail) {
    return <FullPageLoading />;
  }

  return (
    <div className={'page'}>
      <div className="flex-1 flex-column">
        {/*trip code*/}
        <div
          className={'background-main'}
          style={{
            padding: '10px 20px',
          }}
        >
          <p className={'white-text bold h3 text-center'}>
            Mã chuyến: {tripDetail.tripCode}
          </p>
        </div>

        {/*driver info*/}
        <div className={'flex-column'} style={{ gap: 8, padding: '10px 20px' }}>
          <p className={'bold h4 main-text'}>Thông tin tài xế</p>

          <div className={'flex-row align-center'} style={{ gap: 8 }}>
            <img
              alt={'avatar'}
              style={{ height: 45, width: 45 }}
              className={'contain round'}
              src={tripDetail.driver.avatar}
            />
            <div className={'flex-column flex-1'}>
              <p className={'bold h4'}>{tripDetail.driver.name}</p>
              <p className={'h4 gray-text'}>{tripDetail.driver.phone}</p>
            </div>
            <div className={'flex-row'} style={{ gap: 8 }}>
              {/*<div*/}
              {/*  className={'flex-column background-green round center'}*/}
              {/*  style={{*/}
              {/*    height: 35,*/}
              {/*    width: 35,*/}
              {/*  }}*/}
              {/*  data-testid={'message-button'}*/}
              {/*  onClick={handleUserClickOnMessageIcon}*/}
              {/*>*/}
              {/*  <img*/}
              {/*    alt={'message'}*/}
              {/*    style={{*/}
              {/*      height: 25,*/}
              {/*      width: 25,*/}
              {/*    }}*/}
              {/*    className={'contain'}*/}
              {/*    src={Images.message}*/}
              {/*  />*/}
              {/*</div>*/}
              <a
                data-testid={'phone-button'}
                style={{
                  height: 35,
                  width: 35,
                }}
                className={'background-red round flex-column center'}
                onClick={handleUserClickOnMessageIcon}
                href={'tel:' + tripDetail.driver.phone}
              >
                <img
                  alt={'call'}
                  style={{
                    height: 25,
                    width: 25,
                  }}
                  className={'contain'}
                  src={Images.call}
                />
              </a>
            </div>
          </div>
        </div>

        <div className={'horizontal-light-separator'} />

        {/*vehicle info*/}
        <div className={'flex-column'} style={{ gap: 8, padding: '10px 20px' }}>
          <p className={'bold h4 main-text'}>Thông tin xe</p>
          <div
            className="flex-row"
            style={{
              gap: 8,
            }}
          >
            <p className="h4 bold">Xe:</p>
            <p className="h4">{tripDetail.vehicle.description}</p>
          </div>
          <div
            className="flex-row"
            style={{
              gap: 8,
            }}
          >
            <p className="h4 bold">Biển số:</p>
            <p className="h4">{tripDetail.vehicle.licensePlate}</p>
          </div>
        </div>

        <div className={'horizontal-light-separator'} />

        <div
          className={'flex-column'}
          style={{ gap: 12, padding: '10px 20px' }}
        >
          <div className="flex-row">
            <p className="flex-1 bold h4 main-text">Thông tin chuyến</p>
            {/*<p*/}
            {/*  data-testid={'map-button'}*/}
            {/*  onClick={handleUserClickOnMap}*/}
            {/*  className="h4 bold underline text-blue"*/}
            {/*>*/}
            {/*  Bản đồ*/}
            {/*</p>*/}
          </div>

          <div
            className="flex-row"
            style={{
              gap: 8,
            }}
          >
            <p className="h4 bold">Dịch vụ:</p>
            <p className="h4">{tripDetail.service.name}</p>
          </div>

          <div
            className="flex-row"
            style={{
              gap: 8,
            }}
          >
            <p className="h4 bold">Điểm đón:</p>
            <p className="h4 flex-1">{tripDetail.pickupAddress.address}</p>
          </div>

          <div
            className="flex-row"
            style={{
              gap: 8,
            }}
          >
            <p className="h4 bold">Điểm đi:</p>
            <p className="h4 flex-1">{tripDetail.dropOffAddress.address}</p>
          </div>

          {tripDetail.dropOffAddress2 && (
            <div
              className="flex-row"
              style={{
                gap: 8,
              }}
            >
              <p className="h4 bold">Điểm đi 2:</p>
              <p className="h4">{tripDetail.dropOffAddress2.address}</p>
            </div>
          )}

          <div
            className="flex-row"
            style={{
              gap: 8,
            }}
          >
            <p className="h4 bold flex-1">Khoảng cách ước tính:</p>
            <p className="h4 bold main-text">{tripDetail.estimatedDistance}</p>
          </div>
        </div>

        <div
          className={'flex-row space-between background-gray'}
          style={{
            margin: '12px 0',
            padding: '10px 20px',
          }}
        >
          <p className="bold h4">Tổng tiền</p>
          <p className="bold h4">{renderMoney(tripDetail.cost)}</p>
        </div>

        <div
          className={'flex-column'}
          style={{ gap: 12, padding: '10px 20px' }}
        >
          <p className="flex-1 bold h4 main-text">Thời gian dự tính</p>
          <div
            className="flex-row"
            style={{
              gap: 8,
            }}
          >
            <p className="h4 flex-1">Tài xế đến:</p>
            <p className="h4 bold">
              {tripDetail.estimatedDuration.driverArrivedAt}
            </p>
          </div>
          <div
            className="flex-row"
            style={{
              gap: 8,
            }}
          >
            <p className="h4 flex-1">Kết thúc:</p>
            <p className="h4 bold">
              {tripDetail.estimatedDuration.tripEndedAt}
            </p>
          </div>
        </div>

        <div className="horizontal-light-separator" />
      </div>
      {/*<div*/}
      {/*  data-testid={'submit-button'}*/}
      {/*  className="background-main white-text bold flex-row center sticky-bottom"*/}
      {/*  style={{ padding: '10px 20px', marginTop: 12 }}*/}
      {/*>*/}
      {/*  <p>Thanh toán</p>*/}
      {/*</div>*/}

      {/*hotline button*/}
      <HotlineFloatingButton />
    </div>
  );
}
