import AuthenticationService from '../interfaces/services/AuthenticationService';
import StorageService from '../interfaces/services/StorageService';

export default class StorageAuthService implements AuthenticationService {
  private storage: StorageService;
  constructor(storage: StorageService) {
    this.storage = storage;
  }

  async getAuthen() {
    return await this.storage.get('token');
  }

  async savePhone(phone: string) {
    await this.storage.set('phone', phone);
  }

  async getPhone() {
    return await this.storage.get('phone');
  }

  async isAuthen() {
    const token = await this.storage.get('token');
    return token !== null;
  }

  async saveAuthen(token: string) {
    await this.storage.set('token', token);
  }

  async clearAuthen() {
    await this.storage.delete('token');
  }
}
