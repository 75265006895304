import TripDetail from '../../entities/TripDetail';
import moment from 'moment';

export interface GetGoDetailResponse {
  goDetail: GoDetail;
}

export interface GoDetail {
  driver_id: number;
  distance: number;
  user_name: string;
  drop_address: string;
  pickup_address: string;
  pickup_place_id: string;
  driver_lat_start: number;
  pickup_date: string;
  drop_second_latitude: number;
  status_id: number;
  driver_lng_start: number;
  pickup_latitude: number;
  services_option: string;
  car_num: string;
  user_phone: string;
  go_start_time: string;
  drop_latitude: number;
  drop_second_place_id: string;
  id: number;
  drop_longitude: number;
  user_rating: number;
  drop_second_longitude: number;
  butl_cost: number;
  drop_date: string;
  service_detail_id: number;
  cost: number;
  pickup_longitude: number;
  drop_place_id: string;
  driver_avatar_img: string;
  distance_text: string;
  status_name: string;
  payment_status: any;
  driver_cost: number;
  drive_max_time: string;
  driver_phone: string;
  driver_name: string;
  discount_from_code: number;
  go_end_time: string;
  drop_second_address: string;
  user_id: number;
  name: string;
  drop_time: string;
  service_cost: number;
  drive_min_time: string;
  pickup_time: string;
  car_info: string;
  service_option: any[];
}

export function createTripDetailFromResponse(
  response: GetGoDetailResponse,
): TripDetail {
  const getTimeFromDate = (date: string) => {
    return moment(date).format('HH:mm');
  };

  return {
    tripID: response.goDetail.id.toString(),
    tripCode: 'BUTL' + response.goDetail.id.toString(),
    service: {
      id: response.goDetail.service_detail_id,
      name: response.goDetail.name,
      image: '',
    },
    pickupAddress: {
      placeID: response.goDetail.pickup_place_id,
      address: response.goDetail.pickup_address,
      location: {
        lat: response.goDetail.pickup_latitude,
        lng: response.goDetail.pickup_longitude,
      },
    },
    dropOffAddress: {
      placeID: response.goDetail.drop_place_id,
      address: response.goDetail.drop_address,
      location: {
        lat: response.goDetail.drop_latitude,
        lng: response.goDetail.drop_longitude,
      },
    },
    dropOffAddress2: response.goDetail.drop_second_address
      ? {
          placeID: response.goDetail.drop_second_place_id,
          address: response.goDetail.drop_second_address,
          location: {
            lat: response.goDetail.drop_second_latitude,
            lng: response.goDetail.drop_second_longitude,
          },
        }
      : null,
    estimatedDistance: response.goDetail.distance_text,
    cost: response.goDetail.cost,
    estimatedDuration: {
      driverArrivedAt: `${getTimeFromDate(response.goDetail.drive_min_time)} - ${getTimeFromDate(
        response.goDetail.drive_max_time,
      )}`,
      tripEndedAt: `${getTimeFromDate(response.goDetail.go_start_time)} - ${getTimeFromDate(
        response.goDetail.go_end_time,
      )}`,
    },
    driver: {
      name: response.goDetail.driver_name,
      phone: response.goDetail.driver_phone,
      avatar: response.goDetail.driver_avatar_img,
    },
    vehicle: {
      description: response.goDetail.car_info,
      licensePlate: response.goDetail.car_num,
    },
  };
}
