import { useEffect, useState } from 'react';
import { useAuthenService } from '../../providers/ServicesProvider';
import LoginPopup from '../../components/LoginPopup';
import BottomBar from '../../components/BottomBar';
import { Outlet, useLocation } from 'react-router-dom';
import { Tab } from '../../interfaces/entities/Tab';

export default function HomePage() {
  const [isAuthen, setIsAuthen] = useState(false);
  const authService = useAuthenService();
  const [activeTab, setActiveTab] = useState<Tab>('HOME');
  const location = useLocation();

  const checkAuthen = async () => {
    setIsAuthen(await authService.isAuthen());
  };

  const updateActiveTab = (path: string) => {
    switch (path) {
      case '/home':
        setActiveTab('HOME');
        break;
      case '/history':
        setActiveTab('HISTORY');
        break;
      case '/notify':
        setActiveTab('NOTIFY');
        break;
      case '/account':
        setActiveTab('ACCOUNT');
        break;
      default:
        setActiveTab('HOME');
        break;
    }
  };

  useEffect(() => {
    checkAuthen();
  }, []);

  useEffect(() => {
    updateActiveTab(location.pathname);
  }, [location]);

  return (
    <div className={'page'}>
      <div className={'flex-1 flex-column'}>
        <Outlet />
      </div>
      {!isAuthen ? <LoginPopup /> : <BottomBar activeTab={activeTab} />}
    </div>
  );
}
