import { useDispatch, useSelector } from 'react-redux';
import renderMoney from '../../helpers/renderMoney';
import {
  selectBookID,
  selectCost,
  selectDropOffAddress1,
  selectDropOffAddress2,
  selectPickupAddress,
  selectService,
} from '../../redux/slices/bookingSlice';
import { useEffect, useRef, useState } from 'react';
import {
  useNavigationService,
  useTripService,
} from '../../providers/ServicesProvider';
import NotFoundDriverPopup from '../../components/NotFoundDriverPopup';
import Images from '../../assets/images';
import WaveIndicator from '../../components/WaveIndicator';
import Trip from '../../interfaces/entities/Trip';

export const TIMEOUT_S = 3;

export default function MatchPage() {
  const tripID = useSelector(selectBookID);
  const service = useSelector(selectService);
  const cost = useSelector(selectCost);
  const pickupAddress = useSelector(selectPickupAddress);
  const dropOffAddress1 = useSelector(selectDropOffAddress1);
  const dropOffAddress2 = useSelector(selectDropOffAddress2);
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const countdownRef = useRef(TIMEOUT_S);
  const findDriverTimeout = useRef<NodeJS.Timeout | null>(null);
  const [countDownRunning, setCountDownRunning] = useState(false);
  const [countdown, setCountdown] = useState(TIMEOUT_S);
  const [displayNotFoundDriverPopup, setDisplayNotFoundDriverPopup] =
    useState(false);

  const dispatch = useDispatch();
  const tripService = useTripService();
  const navigationService = useNavigationService();

  countdownRef.current = countdown;

  const startCountdown = () => {
    setCountDownRunning(true);
    setCountdown(TIMEOUT_S);

    intervalRef.current = setInterval(() => {
      if (countdownRef.current === 0 && intervalRef.current) {
        clearInterval(intervalRef.current);
        setCountDownRunning(false);
      } else {
        setCountdown(countdownRef.current - 1);
      }
    }, 1000);
  };

  const startFindDriversTimeout = () => {
    findDriverTimeout.current = setTimeout(() => {
      setDisplayNotFoundDriverPopup(true);
      tripService.cancelFindingTrip();
    }, 70000);
  };

  const onCancel = () => {
    // can only cancel when count down is still running
    if (countDownRunning) {
      navigationService.back();
    }
  };

  const onFoundDriver = (info: Trip) => {
    // clear countdown interval
    if (findDriverTimeout.current) clearTimeout(findDriverTimeout.current);

    // navigate to follow trip
    navigationService.navigate('/trip', {
      tripId: info.id,
    });
  };

  const onNotFoundDriver = () => {
    setDisplayNotFoundDriverPopup(true);
  };

  // start countdown timer on default
  useEffect(() => {
    startCountdown();
    startFindDriversTimeout();
  }, []);

  // when countdown is ended, call find drivers
  useEffect(() => {
    if (!countDownRunning) {
      tripService.createTrip(tripID);
    }
  }, [countDownRunning, tripID]);

  // register trip event
  useEffect(() => {
    // on found driver
    const onFoundDriverSub = tripService.onFinishedCreateTrip(onFoundDriver);

    // on not found driver
    const onNotFoundDriverSub =
      tripService.onCreateTripFailed(onNotFoundDriver);

    return () => {
      onFoundDriverSub.remove();
      onNotFoundDriverSub.remove();
    };
  }, []);

  return (
    <div className="page">
      <div className="flex-1 flex-column background-white center">
        <WaveIndicator active={!countDownRunning} />
      </div>
      <div
        className={
          'flex-column medium-border-radius background-white top-heavy-shadow medium-border-radius'
        }
      >
        <div className={'flex-row center'} style={{ marginTop: 15 }}>
          <div
            style={{
              background: '#D1CED5',
              height: 5,
              width: 45,
            }}
          />
        </div>

        <div
          className={'flex-row align-center'}
          style={{
            padding: '15px 20px',
          }}
        >
          <button
            data-testid={'submit-button'}
            className={`full-width background-main button flex-row large-border-radius space-between`}
            style={{
              padding: '16px 24px',
            }}
          >
            <p className={'white-text bold h4'}>{service?.name}</p>

            {cost !== null && (
              <div className={'flex-row align-center'} style={{ gap: 10 }}>
                <p data-testid="estimated-cost" className={'bold h4'}>
                  {renderMoney(cost)}
                </p>
                <img
                  src={Images.cost}
                  className={'contain'}
                  style={{ height: 18, width: 18 }}
                />
              </div>
            )}
          </button>
        </div>

        <div className={'horizontal-light-separator'} style={{ height: 8 }} />

        <div
          className={'flex-column'}
          style={{ gap: 36, padding: '12px 24px' }}
        >
          <div className={'flex-row'} style={{ gap: 15 }}>
            <div
              className={'flex-column center'}
              style={{ height: 15, width: 15 }}
            >
              <div
                className={'address-point background-green'}
                style={{ marginTop: 2 }}
              />
            </div>
            <div className={'flex-column full-width'}>
              <div className={'h6 dark-gray-text'}>Điểm đón</div>
              <p className={'h5'}>{pickupAddress?.address}</p>
            </div>
          </div>

          <div className={'flex-row'} style={{ gap: 15 }}>
            <div
              className={'flex-column center'}
              style={{ height: 15, width: 15 }}
            >
              <div
                className={'address-point background-main'}
                style={{ marginTop: 2 }}
              />
            </div>
            <div className={'flex-column full-width'}>
              <div className={'h6 dark-gray-text'}>Điểm đi</div>
              <p className={'h5'}>{dropOffAddress1?.address}</p>
            </div>
          </div>
          {dropOffAddress2 && (
            <div className={'flex-row'} style={{ gap: 15 }}>
              <div
                className={'flex-column center'}
                style={{ height: 15, width: 15 }}
              >
                <div
                  className={'address-point background-main'}
                  style={{ marginTop: 2 }}
                />
              </div>
              <div className={'flex-column full-width'}>
                <div className={'h6 dark-gray-text'}>Điểm đi 2</div>
                <p className={'h5'}>{dropOffAddress2?.address}</p>
              </div>
            </div>
          )}
        </div>

        <div
          onClick={onCancel}
          data-testid={'cancel-finding-driver'}
          className={'background-main white-text h4 bold flex-row center'}
          style={{ padding: '15px 0px', marginTop: 10 }}
        >
          <p>{countDownRunning ? 'Hủy tìm tài xế' : 'Đang tìm tài xế'}</p>
          {countDownRunning && (
            <div
              className={
                'round background-white flex-column center absolute-right'
              }
              style={{ height: 35, width: 35, right: 15 }}
            >
              <p data-testid="cancel-countdown" className={'main-text'}>
                {countdown}
              </p>
            </div>
          )}
        </div>
      </div>

      {displayNotFoundDriverPopup && <NotFoundDriverPopup />}
    </div>
  );
}
