import Header from '../../components/Header';
import Texts from '../../text/texts';
import IconTextInput from '../../components/IconTextInput';
import React, { useState } from 'react';
import validateEmail from '../../helpers/validateEmail';
import {
  useAlertService,
  useDataService,
  useNavigationService,
} from '../../providers/ServicesProvider';

export default function UpdateInfoPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const dataService = useDataService();
  const navigationService = useNavigationService();
  const alertService = useAlertService();

  const onSubmit = async () => {
    try {
      if (name.length === 0) {
        setError(Texts.updateInfo.errors.nullName);
        return;
      }

      if (email.length === 0) {
        setError(Texts.updateInfo.errors.nullEmail);
        return;
      }

      if (!validateEmail(email)) {
        setError(Texts.updateInfo.errors.invalidEmail);
        return;
      }

      if (password.length === 0) {
        setError(Texts.updateInfo.errors.nullPassword);
        return;
      }

      await dataService.updateUserInfo(name, email, password);
      navigationService.navigate('/', null);
    } catch (error: any) {
      alertService.error(error.message);
    }
  };

  return (
    <div className={'page'}>
      <Header title={Texts.updateInfo.title} />
      <div className={'flex-column flex-1 default-padding'}>
        <div className={'flex-1 flex-column'} style={{ gap: 15 }}>
          <IconTextInput
            icon={'user'}
            placeholder={Texts.updateInfo.nameInput}
            type={'text'}
            onChangeText={text => setName(text)}
          />
          <IconTextInput
            icon={'phone'}
            placeholder={Texts.updateInfo.emailInput}
            type={'email'}
            onChangeText={text => setEmail(text)}
          />
          <IconTextInput
            icon={'key'}
            placeholder={Texts.updateInfo.passwordInput}
            type={'password'}
            onChangeText={text => setPassword(text)}
          />
          {error && (
            <span className={'error-text h5 text-center'}>{error}</span>
          )}
        </div>
        <div className={'flex-column'}>
          <button className={'button h5 bold'} onClick={onSubmit}>
            {Texts.updateInfo.registerButton}
          </button>
        </div>
      </div>
    </div>
  );
}
