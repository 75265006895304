export default function renderMoney(num: number) {
  const numString = num.toString();
  if (numString.length < 4) return numString + ' vnđ';

  let result = '';
  for (let i = numString.length - 1; i >= 0; i--) {
    const reverseIndex = numString.length - 1 - i;
    if (reverseIndex % 3 == 0 && reverseIndex !== 0) {
      result = '.' + result;
    }

    result = numString[i] + result;
  }

  result = result + ' vnđ';
  return result;
}
