import Service from '../interfaces/entities/Service';
import Texts from '../text/texts';
import renderMoney from '../helpers/renderMoney';
import Images from '../assets/images';
import { Fragment, useState } from 'react';
import PaymentSelectionPopup from './PaymentSelectionPopup';
import PromotionSelectionPopup from './PromotionSelectionPopup';
import Address from '../interfaces/entities/Address';
import { useAlertService, useDataService } from '../providers/ServicesProvider';
import NotePopup from './NotePopup';
import { useDispatch, useSelector } from 'react-redux';
import {
  bookingAction,
  selectCost,
  selectDropOffAddress1,
  selectOldCost,
  selectPickupAddress,
  selectSelectedServiceType,
  selectService,
  selectServiceTypes,
} from '../redux/slices/bookingSlice';
import { ServiceType } from '../interfaces/entities/ServiceType';
import Promotion from '../interfaces/entities/Promotion';
import hasEnoughDataForBooking from '../helpers/hasEnoughDataForBooking';

export default function ServicePanel(props: { onSubmit: () => void }) {
  const { onSubmit } = props;
  const [displayPaymentPopup, setDisplayPaymentPopup] = useState(false);
  const [displayPromotionPopup, setDisplayPromotionPopup] = useState(false);
  const [displayNotePopup, setDisplayNotePopup] = useState(false);
  const [avaiablePromotions, setAvaiablePromotions] = useState<Promotion[]>([]);
  const dispatch = useDispatch();
  const service = useSelector(selectService);
  const cost = useSelector(selectCost);
  const oldCost = useSelector(selectOldCost);
  const pickupAddress = useSelector(selectPickupAddress);
  const dropOffAddress1 = useSelector(selectDropOffAddress1);
  const selectedServiceType = useSelector(selectSelectedServiceType);
  const serviceTypes = useSelector(selectServiceTypes);
  const dataService = useDataService();
  const notificationService = useAlertService();

  const submitButtonDisabled = !hasEnoughDataForBooking(
    pickupAddress,
    dropOffAddress1,
    selectedServiceType,
  );

  const onInputPromotionCode = async (promotion: string) => {
    try {
      const isValid = await dataService.validatePromotionCode(promotion);

      if (isValid) {
        setDisplayPromotionPopup(false);
        dispatch(bookingAction.setPromotion(promotion));
      } else {
        throw new Error(Texts.promotion.errors.invalidCode);
      }
    } catch (error: any) {
      notificationService.error(error.message);
    }
  };

  const onInputNote = (note: string) => {
    dispatch(bookingAction.setNote(note));
    setDisplayNotePopup(false);
  };

  const preparePopupData = async () => {
    try {
      if (!selectedServiceType) return;
      if (!pickupAddress) return;

      // get promotions from data service
      const _promotions = await dataService.getPromotionCodes(
        selectedServiceType,
        pickupAddress,
      );
      setAvaiablePromotions(_promotions);
      setDisplayPromotionPopup(true);
    } catch (error: any) {
      notificationService.error(error.message);
    }
  };

  const onClickServiceType = (serviceType: ServiceType) =>
    dispatch(bookingAction.setSelectedServiceType(serviceType));

  if (!selectedServiceType) return null;

  return (
    <Fragment>
      <div
        className={
          'absolute-bottom layer-2 flex-column background-white big-border-radius top-shadow'
        }
        style={{ padding: '26px 0px', gap: 12 }}
      >
        <div
          className="flex-column"
          style={{ maxHeight: 60 * 2 + 60 / 2, overflowY: 'scroll' }}
        >
          {serviceTypes.map((serviceType, index) => {
            const selected = serviceType.id === selectedServiceType.id;
            return (
              <div
                className={`flex-row align-center ${selected && 'background-main-3'}`}
                style={{ padding: '10px 25px', gap: 25, height: 40 }}
                onClick={() => onClickServiceType(serviceType)}
              >
                <img
                  className={'contain round'}
                  src={service?.image}
                  style={{ height: 40, width: 40 }}
                  alt={''}
                />
                <p
                  className={'flex-1 h4 bold'}
                  data-testid={
                    selected
                      ? 'service-type-name-selected'
                      : 'service-type-name'
                  }
                >
                  {serviceType.serviceName}
                </p>
                <img
                  className={'contain round'}
                  src={Images.info}
                  style={{ height: 22, width: 22 }}
                  alt={''}
                />
              </div>
            );
          })}
        </div>

        <div
          className={'horizontal-separator'}
          style={{ background: '#F8F8FC', height: 8 }}
        />

        <div
          className={'flex-row space-between'}
          style={{ padding: '0px 20px', gap: 10 }}
        >
          <div
            className={
              'flex-1 center large-border-radius gray-border card-shadow touchable'
            }
            style={{ padding: 9 }}
            onClick={() => setDisplayPaymentPopup(true)}
          >
            <p>{Texts.bookPage.payment}</p>
          </div>
          <div
            className={
              'flex-1 center large-border-radius gray-border card-shadow touchable'
            }
            style={{ padding: 9 }}
            onClick={preparePopupData}
          >
            <p>{Texts.bookPage.promotion}</p>
          </div>
          <div
            className={
              'flex-1 center large-border-radius gray-border card-shadow touchable'
            }
            style={{ padding: 9 }}
            onClick={() => setDisplayNotePopup(true)}
          >
            <p>{Texts.bookPage.note}</p>
          </div>
        </div>

        <div style={{ padding: '0px 20px', marginTop: 4 }}>
          <button
            data-testid={'submit-button'}
            disabled={submitButtonDisabled}
            className={`background-main button flex-row full-width large-border-radius ${submitButtonDisabled ? 'disabled disabled-color' : 'space-between'}`}
            onClick={onSubmit}
            style={{
              padding: '16px 24px',
            }}
          >
            <p className={'white-text bold h4'}>{service?.name}</p>

            {cost !== null && (
              <div className={'flex-row align-center'} style={{ gap: 10 }}>
                <div className={'flex-column'}>
                  {oldCost && (
                    <p
                      data-testid="estimated-cost"
                      className={'bold h5'}
                      style={{
                        color: 'black',
                        textDecoration: 'line-through',
                      }}
                    >
                      {renderMoney(oldCost)}
                    </p>
                  )}

                  <p data-testid="estimated-cost" className={'bold h4'}>
                    {renderMoney(cost)}
                  </p>
                </div>
                <img
                  src={Images.cost}
                  className={'contain'}
                  style={{ height: 18, width: 18 }}
                />
              </div>
            )}
          </button>
        </div>
      </div>

      {displayPaymentPopup && (
        <PaymentSelectionPopup
          onFinish={() => setDisplayPaymentPopup(false)}
          onClose={() => setDisplayPaymentPopup(false)}
        />
      )}

      {displayPromotionPopup && (
        <PromotionSelectionPopup
          onFinish={onInputPromotionCode}
          onClose={() => setDisplayPromotionPopup(false)}
          promotions={avaiablePromotions}
        />
      )}

      {displayNotePopup && (
        <NotePopup
          onFinish={onInputNote}
          onClose={() => setDisplayNotePopup(false)}
        />
      )}
    </Fragment>
  );
}
