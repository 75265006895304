export default interface Promotion {
  title: string;
  description: string;
  code: string;
  image: string;
}

// create fake promotion
export function createFakePromotion(): Promotion {
  return {
    title: `Promotion ${Math.floor(Math.random() * 1000)}`,
    description: `Description ${Math.floor(Math.random() * 1000)} lorem iup sum dolor sit amet.`,
    code: `code${Math.floor(Math.random() * 1000)}`,
    image: '',
  };
}

/**
 * Checks if a promotion is valid by comparing its code with a list of promotions.
 * @param promotion - The promotion to check.
 * @param list - The list of promotions to compare against.
 * @returns A boolean indicating whether the promotion is valid or not.
 */
export function existInPromotionList(
  promotionCode: string,
  list: Promotion[],
): boolean {
  return list.some(p => p.code === promotionCode);
}
