import Address from '../../entities/Address';
import { PaymentType } from '../../entities/PaymentType';
import { ServiceType } from '../../entities/ServiceType';

export default interface EstimateCostRequest {
  serviceDetailID: number;
  pickupAddress: string;
  dropAddress: string;
  dropSecondAddress?: string;
  pickupPlaceID: string;
  dropPlaceID: string;
  dropSecondPlaceID?: string;
  pickupDate: number;
  dropDate: number;
  servicesOption: any[];
  giftCode: string;
}

export function createEstimateCostRequest(params: {
  pickup: Address;
  dropOff_1: Address | null;
  dropOff_2: Address | null;
  serviceType: ServiceType;
  paymentType: PaymentType;
  promotion?: string;
  startDate: Date;
  endDate: Date;
}) {
  return {
    serviceDetailID: params.serviceType.id,
    pickupAddress: params.pickup.address,
    dropAddress: params.dropOff_1?.address || '',
    pickupPlaceID: params.pickup.placeID,
    dropPlaceID: params.dropOff_1?.placeID || '',
    pickupDate: params.startDate.getTime(),
    dropDate: params.endDate.getTime(),
    servicesOption: [],
    giftCode: params.promotion || '',
    ...(params.dropOff_2
      ? {
          dropSecondAddress: params.dropOff_2.address,
          dropSecondPlaceID: params.dropOff_2.placeID,
        }
      : {}),
  };
}
