import { ServiceType } from '../../entities/ServiceType';
import { BookType } from '../../entities/BookType';

export default interface ServiceTypeDTO {
  id: number;
  typeID: number;
  serviceName: string;
  serviceID: number;
  image: string;
  form: number;
  policy_content: string;
  is_active_driver_grab: number;
  active: boolean;
}

export function createBookTypeFromDTO(dto: ServiceTypeDTO): BookType {
  if (dto.form === 1) {
    return '1_ADDRESS';
  }
  if (dto.form === 2) {
    return '2_ADDRESS';
  }
  if (dto.form === 3) {
    return '1_ADDRESS_1_DAY';
  }
  if (dto.form === 4) {
    return '1_ADDRESS_2_DAY';
  }
  if (dto.form === 5) {
    return '2_ADDRESS_1_DAY';
  }
  if (dto.form === 6) {
    return '2_ADDRESS_2_DAY';
  }
  return '2_ADDRESS_2_DAY';
}

export function createServiceTypeFromDTO(dto: ServiceTypeDTO): ServiceType {
  return {
    id: dto.id,
    typeID: dto.typeID,
    serviceName: dto.serviceName,
    serviceID: dto.serviceID,
    image: dto.image,
    form: dto.form,
    policy_content: dto.policy_content,
    is_active_driver_grab: dto.is_active_driver_grab,
    active: dto.active,
    type: createBookTypeFromDTO(dto),
  };
}
