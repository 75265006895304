import { useState } from 'react';

export default function NotePopup(props: {
  onFinish: (note: string) => void;
  onClose: () => void;
}) {
  const [note, setNote] = useState('');
  const disabledConfirm = note.length === 0;
  return (
    <div
      className={
        'absolute-full-page popup-background-color layer-3 center flex-column'
      }
      data-testid={'dim-background'}
      onClick={props.onClose}
    >
      <div
        data-testid={'note-popup'}
        className={'flex-column small-border-radius background-white'}
        style={{
          padding: '25px 20px',
          gap: 25,
          width: '70%',
        }}
        onClick={(e: any) => e.stopPropagation()}
      >
        <p className={'bold h3 main-text text-center'}>Ghi chú</p>

        <input
          className={
            'input h4 medium full-width gray-border tiny-border-radius text-center'
          }
          placeholder={'Nhập ghi chú'}
          style={{ height: 60 }}
          data-testid={'note-popup-input'}
          onChange={event => setNote(event.target.value)}
        />
        <button
          className={`button bold h4 card-shadow ${disabledConfirm && 'disabled disabled-color'}`}
          data-testid={'note-popup-confirm'}
          disabled={disabledConfirm}
          onClick={() => props.onFinish(note)}
        >
          XÁC NHẬN
        </button>
      </div>
    </div>
  );
}
