import Address from '../interfaces/entities/Address';
import Texts from '../text/texts';
import Images from '../assets/images';
import { useNavigationService } from '../providers/ServicesProvider';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import {
  bookingAction,
  selectDropOffAddress1,
  selectDropOffAddress2,
  selectEndDay,
  selectPickupAddress,
  selectSelectedServiceType,
  selectStartDay,
} from '../redux/slices/bookingSlice';
import { vi } from 'date-fns/locale/vi';
import { Fragment, useState } from 'react';
import parseDateToDateString from '../helpers/parseDateToDateString';
import 'react-datepicker/dist/react-datepicker.css';
import displayAddressesBaseOnBookingType from '../helpers/displayAddressesBaseOnBookingType';
import { PickAddressType } from '../screens/book/PickAddressPage';

registerLocale('vi', vi);
setDefaultLocale('vi');

export default function AddressPicker() {
  const serviceType = useSelector(selectSelectedServiceType);

  const pickupAddress = useSelector(selectPickupAddress);
  const dropOffAddress1 = useSelector(selectDropOffAddress1);
  const dropOffAddress2 = useSelector(selectDropOffAddress2);

  const dispatch = useDispatch();

  const startDate = useSelector(selectStartDay);
  const endDate = useSelector(selectEndDay);

  const navigationService = useNavigationService();

  const onClick = (type: PickAddressType) =>
    navigationService.navigate('/pick-address', {
      type,
    });

  if (!serviceType) return null;

  const displayAddresses = displayAddressesBaseOnBookingType(serviceType.type);
  const displayPickDatePanel =
    displayAddresses.startDay || displayAddresses.endDay;

  return (
    <div
      className={
        'absolute-top layer-2 background-white big-border-radius card-shadow flex-column'
      }
      style={{
        margin: '50px 15px',
        padding: '21px 24px',
        gap: 13,
      }}
    >
      <div
        className={'flex-row'}
        style={{ gap: 15 }}
        onClick={() => onClick('PICKUP')}
      >
        <div className={'flex-column center'} style={{ height: 15, width: 15 }}>
          <div
            className={'address-point background-green'}
            style={{ marginTop: 2 }}
          />
        </div>
        <div className={'flex-column full-width'}>
          <div className={'h6 dark-gray-text'}>
            {Texts.bookPage.pickUpTitle}
          </div>
          <p data-testid={'pickup-address'} className={'h5'}>
            {pickupAddress ? pickupAddress.address : 'Chọn điểm đón'}
          </p>
        </div>
      </div>

      <div className={'horizontal-separator full-width'} />

      {displayAddresses.dropoff1 ? (
        <Fragment>
          <div
            className={'flex-row touchable'}
            style={{ gap: 15 }}
            onClick={() => onClick('DROPOFF_1')}
            data-testid={'dropoff1'}
          >
            <div
              className={'flex-column center'}
              style={{ height: 15, width: 15 }}
            >
              <div
                className={'address-point background-main'}
                style={{ marginTop: 2 }}
              />
            </div>
            <div className={'flex-column full-width'}>
              <div className={'h6 dark-gray-text'}>
                {Texts.bookPage.dropOffTitle}
              </div>
              <p data-testid={'drop-off-address-1'} className={'h5'}>
                {dropOffAddress1
                  ? dropOffAddress1.address
                  : Texts.bookPage.chooseDropOffTitle}
              </p>
            </div>
          </div>

          <div className={'horizontal-separator full-width'} />

          {displayAddresses.dropoff2 && dropOffAddress2 ? (
            <div
              className={'flex-row touchable'}
              style={{ gap: 15 }}
              onClick={() => onClick('DROPOFF_2')}
              data-testid={'dropoff2'}
            >
              <div
                className={'flex-column center'}
                style={{ height: 15, width: 15 }}
              >
                <div
                  className={'address-point background-main'}
                  style={{ marginTop: 2 }}
                />
              </div>
              <div className={'flex-column full-width'}>
                <div className={'h6 dark-gray-text'}>
                  {Texts.bookPage.dropOffTitle_2}
                </div>
                <div className={'flex-row align-center space-between'}>
                  <p data-testid={'drop-off-address-2'} className={'h5'}>
                    {dropOffAddress2.address}
                  </p>
                  <div
                    data-testid={'remove-drop-off-2'}
                    onClick={event => {
                      event.stopPropagation();
                      dispatch(bookingAction.setDropOffAddress2(null));
                    }}
                    className={'flex-column center'}
                    style={{
                      height: 30,
                      width: 30,
                    }}
                  >
                    <div
                      className={'touchable background-main'}
                      style={{
                        height: 2,
                        width: 10,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={'flex-row touchable'}
              style={{ gap: 15 }}
              onClick={() => onClick('DROPOFF_2')}
              data-testid={'dropoff2'}
            >
              <img
                src={Images.plus}
                style={{ height: 15, width: 15 }}
                className={'contain'}
                alt={''}
              />
              <div className={'flex-column full-width'}>
                <div className={'h6 gray-text'}>
                  {Texts.bookPage.chooseDropOffTitle_2}
                </div>
              </div>
            </div>
          )}
        </Fragment>
      ) : null}

      {displayPickDatePanel && (
        <div
          className={'flex-row'}
          style={{
            gap: 15,
            height: 50,
          }}
        >
          <div
            className={'flex-column center'}
            style={{ height: 15, width: 15 }}
          >
            <div
              className={'address-point background-main'}
              style={{ marginTop: 2 }}
            />
          </div>

          <div
            className={'flex-1 flex-row space-between'}
            style={{
              padding: '0 20% 0 0px',
            }}
          >
            {displayAddresses.startDay && (
              <div className={'flex-column'} style={{ gap: 8 }}>
                <p className={'h6 dark-gray-text'}>Ngày đi</p>
                <DatePicker
                  value={parseDateToDateString(startDate)}
                  popperPlacement={'bottom-start'}
                  customInput={
                    <input className={'h5 input'} data-testid={'start-day'} />
                  }
                  onChange={value =>
                    dispatch(bookingAction.setStartDay(value || new Date()))
                  }
                />
              </div>
            )}
            {displayAddresses.endDay && (
              <div className={'flex-column'} style={{ gap: 8 }}>
                <p className={'h6 dark-gray-text'}>Ngày về</p>
                <DatePicker
                  onChange={value =>
                    dispatch(bookingAction.setEndDay(value || new Date()))
                  }
                  customInput={
                    <input className={'h5 input'} data-testid={'end-day'} />
                  }
                  value={parseDateToDateString(endDate)}
                  popperPlacement={'bottom-start'}
                  minDate={startDate}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
