import Images from '../assets/images';

export default function StarComponent(props: {
  star: number;
  onUpdate?: (star: number) => void;
  size?: 'small' | 'medium' | 'large';
}) {
  const size = (() => {
    if (props.size === 'small') return 14;
    if (props.size === 'medium') return 24;
    if (props.size === 'large') return 24;
  })();

  const space = (() => {
    if (props.size === 'small') return 0;
    if (props.size === 'medium') return 10;
    if (props.size === 'large') return 14;
  })();

  const onClick = (index: number) => {
    if (props.onUpdate) props.onUpdate(index + 1);
  };
  return (
    <div
      data-testid={'star-component'}
      className={'flex-row'}
      style={{
        gap: space,
      }}
    >
      {Array.from({ length: 5 }).map((_, index) => {
        const isActive = index < props.star;
        return (
          <img
            data-testid={isActive ? 'active-star' : 'inactive-star'}
            key={index}
            onClick={() => onClick(index)}
            src={isActive ? Images.activeStar : Images.inactiveStar}
            style={{
              width: size,
              height: size,
            }}
            alt={'star'}
          />
        );
      })}
    </div>
  );
}
