import NavigationService from '../interfaces/services/NavigationService';
import { NavigateFunction } from 'react-router-dom';

export default class NavigationRouterService implements NavigationService {
  private _navigate: NavigateFunction;
  constructor(navigate: NavigateFunction) {
    this._navigate = navigate;
  }
  navigate(path: string, params: any) {
    this._navigate(path, {
      state: params,
    });
  }
  back() {
    this._navigate(-1);
  }
  replace(path: string, params: any) {
    this._navigate(path, {
      replace: true,
    });
  }
}
