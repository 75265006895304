export default interface CheckGoResponse {
  res: {
    hasGoRunning: boolean;
    go: {
      goID: string;
    };
  };
}

export function isOnTrip(response: CheckGoResponse): boolean {
  return response.res.hasGoRunning;
}

export function getTripID(response: CheckGoResponse): string {
  return response.res.go.goID;
}
