import { ServiceType } from '../../entities/ServiceType';
import TripHistory from '../../entities/TripHistory';
import parseDateToDateString, {
  parseDateToDateTimeString,
} from '../../../helpers/parseDateToDateString';

export interface GetHistoryResponse {
  histories: HistoryResponse[];
}

export interface HistoryResponse {
  goID: number;
  serviceID: number;
  serviceDetailID: number;
  timeStamp: number;
  pickupAddress: string;
  dropAddress: string;
  pickupDate: number;
  dropDate: number;
  rating: number;
  originalCost: number;
  discountCost: number;
  discountFromCode: number;
  giftCode: any;
  cost: number;
  serviceCost: number;
  name: string;
  phone: string;
  avatarImg: string;
  pickup_address: string;
  pickup_latitude: number;
  pickup_longitude: number;
  pickup_place_id: string;
  pickup_time: string;
  pickup_date: string;
  drop_address: string;
  drop_latitude: number;
  drop_longitude: number;
  drop_place_id: string;
  drop_time: string;
  drop_date: any;
  progress: number;
  cancel_reason: string;
  gift_code: string;
  original_cost: number;
  servicesOption: any[];
  drop_second_address: any;
  drop_second_latitude: number;
  drop_second_longitude: number;
  drop_second_place_id: any;
}

export function createHistoryFromResponse(
  response: GetHistoryResponse,
  serviceTypes: ServiceType[],
): TripHistory[] {
  const result: TripHistory[] = [];
  response.histories.forEach(history => {
    const serviceType = serviceTypes.find(
      serviceType => serviceType.serviceID === history.serviceID,
    );
    if (serviceType) {
      result.push({
        id: history.goID.toString(),
        code: history.goID.toString(),
        name: serviceType.serviceName,
        date: parseDateToDateTimeString(new Date(history.pickup_time)),
        rate: history.rating,
        pickup: history.pickupAddress,
        dropoff1: history.dropAddress || null,
        dropoff2: history.drop_second_address,
        startDay: parseDateToDateString(new Date(history.pickup_date)),
        endDay: history.drop_date || '',
        type: serviceType.type,
        insuranceCost: history.originalCost,
        totalCost: history.cost,
        discount: history.discountCost,
        totalPayment: history.cost - history.discountCost,
        image: serviceType.image,
      });
    }
  });
  return result;
}
