import Texts from '../text/texts';
import { useNavigationService } from '../providers/ServicesProvider';
import Images from '../assets/images';
import { Tab } from '../interfaces/entities/Tab';

function BottomButton(props: {
  onClick: () => void;
  title: string;
  isActive?: boolean;
  type: Tab;
}) {
  const icon = (() => {
    switch (props.type) {
      case 'HOME':
        return props.isActive ? Images.homeActive : Images.home;
      case 'HISTORY':
        return props.isActive ? Images.historyActive : Images.history;
      case 'NOTIFY':
        return props.isActive ? Images.notifyActive : Images.notify;
      case 'ACCOUNT':
        return props.isActive ? Images.accountActive : Images.account;
      default:
        return props.isActive ? Images.homeActive : Images.home;
    }
  })();

  return (
    <div
      onClick={props.onClick}
      className={`center flex-column flex-1 ${props.isActive ? 'active-bottom-button' : 'inactive-bottom-button'}`}
      style={{ gap: 8, padding: '12px 0px' }}
      data-testid={`bottom-button-${props.type}`}
    >
      <img
        src={icon}
        className={'contain'}
        alt={'icon'}
        style={{
          height: 21,
          width: 21,
        }}
      />
      <p className={`${props.isActive ? 'bold' : 'medium'} h5`}>
        {props.title}
      </p>
    </div>
  );
}

export default function BottomBar(props: { activeTab: Tab }) {
  const navigationService = useNavigationService();

  const onClickHome = () => {
    navigationService.navigate('/home', null);
  };

  const onClickHistory = () => {
    navigationService.navigate('/history', null);
  };

  const onClickNotify = () => {
    navigationService.navigate('/notify', null);
  };

  const onClickAccount = () => {
    navigationService.navigate('/account', null);
  };

  return (
    <div
      className={
        'flex-row align-center top-shadow background-white sticky-bottom layer-2'
      }
      style={{ gap: 12 }}
    >
      <BottomButton
        onClick={onClickHome}
        title={Texts.bottomBar.home}
        isActive={props.activeTab === 'HOME'}
        type={'HOME'}
      />
      <BottomButton
        onClick={onClickHistory}
        title={Texts.bottomBar.history}
        type={'HISTORY'}
        isActive={props.activeTab === 'HISTORY'}
      />
      {/*<BottomButton*/}
      {/*  onClick={onClickNotify}*/}
      {/*  title={Texts.bottomBar.notify}*/}
      {/*  type={'NOTIFY'}*/}
      {/*  isActive={props.activeTab === 'NOTIFY'}*/}
      {/*/>*/}
      <BottomButton
        onClick={onClickAccount}
        title={Texts.bottomBar.account}
        type={'ACCOUNT'}
        isActive={props.activeTab === 'ACCOUNT'}
      />
    </div>
  );
}
