import React from 'react';
import Main from './screens/Main';

function App() {
  return (
    <div className="full-screen flex-column align-center background-main-3">
      <div className="flex-column card-shadow">
        <Main />
      </div>
    </div>
  );
}

export default App;
