import {
  useDataService,
  useNavigationService,
} from '../providers/ServicesProvider';

export default function NotFoundDriverPopup() {
  const dataService = useDataService();
  const navigationService = useNavigationService();
  const hotline = dataService.getHotLineNumber();

  const onClickOk = () => {
    navigationService.back();
  };

  return (
    <div
      className={
        'absolute-full-page popup-background-color layer-3 center flex-column'
      }
    >
      <div
        data-testid={'not-found-driver-popup'}
        className={'flex-column small-border-radius background-white'}
        style={{
          padding: '25px 20px',
          gap: 22,
          width: '70%',
        }}
      >
        <p className={'bold h3 main-text text-center'}>Thông báo</p>
        <p className={'h4 text-center'}>Không tìm thấy xe</p>

        <div className={'flex-column'} style={{ gap: 10 }}>
          <a
            data-testid={'call-hotline-button'}
            href={`tel:${hotline}`}
            className={'button bold h4 card-shadow'}
            style={{
              textDecoration: 'none',
            }}
          >
            GỌI HOTLINE
          </a>
          <button
            data-testid={'ok-button'}
            onClick={onClickOk}
            className={'button bold h4 card-shadow'}
          >
            XÁC NHẬN
          </button>
        </div>
      </div>
    </div>
  );
}
