import Address from '../../entities/Address';
import { ServiceType } from '../../entities/ServiceType';
import { PaymentType } from '../../entities/PaymentType';
import DoEstimateResponse from '../response/DoEstimateResponse';

export default interface BookTripRequest {
  serviceDetailID: number;
  serviceID: number;
  pickupAddress: string;
  dropAddress: string;
  dropSecondAddress?: string;
  pickupPlaceID: string;
  dropPlaceID: string;
  dropSecondPlaceID?: string;
  pickupDate: number;
  dropDate: number;
  pickupLatitude: number;
  pickupLongitude: number;
  dropLatitude: number;
  dropLongitude: number;
  dropSecondLatitude?: number;
  dropSecondLongitude?: number;
  servicesOption: any[];
  mapData: string;
  giftCode: string;
  cost: number;
  distance: number;
  distanceText: string;
}

export function createBookTripRequest(params: {
  pickup: Address;
  dropOff_1: Address;
  dropOff_2: Address | null;
  serviceType: ServiceType;
  paymentType: PaymentType;
  promotion?: string;
  previousEstimateDTO: DoEstimateResponse;
}): BookTripRequest {
  return {
    serviceDetailID: params.serviceType.id,
    serviceID: params.serviceType.serviceID,
    pickupAddress: params.pickup.address,
    dropAddress: params.dropOff_1.address,
    pickupPlaceID: params.pickup.placeID,
    dropPlaceID: params.dropOff_1.placeID,
    pickupDate: Date.now(),
    dropDate: Date.now(),
    pickupLatitude: params.pickup.location.lat,
    pickupLongitude: params.pickup.location.lng,
    dropLatitude: params.dropOff_1.location.lat,
    dropLongitude: params.dropOff_1.location.lng,
    servicesOption: [],
    mapData: params.previousEstimateDTO.res.mapData,
    giftCode: '',
    cost: params.previousEstimateDTO.res.cost_estimate_original,
    distance: params.previousEstimateDTO.res.distance,
    distanceText: params.previousEstimateDTO.res.distanceText,
    ...(params.dropOff_2
      ? {
          dropSecondAddress: params.dropOff_2.address,
          dropSecondPlaceID: params.dropOff_2.placeID,
          dropSecondLatitude: params.dropOff_2.location.lat,
          dropSecondLongitude: params.dropOff_2.location.lng,
        }
      : {}),
  };
}
