import { CONFIG } from '../Config';

class Logger {
  private shouldLog = CONFIG.LOG;

  log(message: any, data?: any) {
    if (this.shouldLog) {
      console.log(message, data);
    }
  }
}

export const logger = new Logger();
