import { useEffect, useState } from 'react';
import Profile from '../../interfaces/entities/Profile';
import {
  useAlertService,
  useAuthenService,
  useDataService,
  useNavigationService,
} from '../../providers/ServicesProvider';
import Images from '../../assets/images';

export default function AccountPage() {
  const dataService = useDataService();
  const alertService = useAlertService();
  const authenService = useAuthenService();
  const navigationService = useNavigationService();

  const [profile, setProfile] = useState<Profile | null>(null);

  const fetchProfile = async () => {
    try {
      const profile = await dataService.getProfile();
      setProfile(profile);
    } catch (e: any) {
      alertService.error(e.message);
    }
  };

  const logout = async () => {
    await authenService.clearAuthen();
    navigationService.replace('/login', null);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  if (!profile) return null;

  return (
    <div className={'flex-1 flex-column background-main'}>
      <div
        className={'flex-column center'}
        style={{
          padding: '20px 0',
        }}
      >
        <p className={'h3 white-text bold'}>Tài Khoản</p>
      </div>
      <div
        className={'flex-1 flex-column medium-border-radius background-white'}
        style={{
          padding: 25,
          marginBottom: -10,
        }}
      >
        <div className={'flex-row align-center'}>
          <div
            className={'flex-column center background-main card-shadow round'}
            style={{
              height: 65,
              width: 65,
            }}
          >
            <img
              src={profile.avatar || Images.profile}
              alt={'avatar'}
              className={'contain round background-main'}
              style={{ width: 40, height: 40 }}
            />
          </div>

          <div className={'flex-column'} style={{ marginLeft: 15, gap: 4 }}>
            <div data-testid={'name'} className={'bold h4'}>
              {profile.name}
            </div>
            <div data-testid={'phone'} className={'gray-text'}>
              {profile.phone}
            </div>
          </div>
        </div>
        <div
          data-testid={'logout'}
          onClick={logout}
          className={'flex-row border-bottom'}
          style={{
            marginTop: 24,
            padding: 15,
          }}
        >
          <p className={'bold h4'}>Đăng Xuất</p>
        </div>
      </div>
    </div>
  );
}
