import Promotion from '../../entities/Promotion';

export default interface GetPromotionResponse {
  discounts: DiscountDTO[];
}

export interface DiscountDTO {
  title: string;
  description: string;
  discount_code: string;
}

export function createPromotionsFromDiscountDTOs(
  dtos: DiscountDTO[],
): Promotion[] {
  return dtos.map(createPromotionFromDiscountDTO);
}

function createPromotionFromDiscountDTO(dto: DiscountDTO): Promotion {
  return {
    title: dto.title,
    description: dto.description,
    code: dto.discount_code,
    image: '',
  };
}
