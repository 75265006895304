import { useDispatch } from 'react-redux';
import Service from '../interfaces/entities/Service';
import ServiceGroup from '../interfaces/entities/ServiceGroup';
import {
  useAlertService,
  useAuthenService,
  useDataService,
  useNavigationService,
  useTripService,
} from '../providers/ServicesProvider';
import { bookingAction } from '../redux/slices/bookingSlice';

export default function ServiceGroupComponent(props: {
  serviceGroup: ServiceGroup;
}) {
  const { serviceGroup } = props;
  const authenService = useAuthenService();
  const navigationService = useNavigationService();
  const dataService = useDataService();
  const tripService = useTripService();
  const alertService = useAlertService();
  const dispatch = useDispatch();
  const onClick = async (service: Service) => {
    try {
      const isAuthen = await authenService.isAuthen();
      if (!isAuthen) {
        navigationService.navigate('/login', null);
        return;
      }

      const checkTrip = await dataService.checkOnTrip();
      if (checkTrip.isOnTrip) {
        tripService.continueTrip(checkTrip.tripId!);
        navigationService.navigate('/trip', {
          tripId: checkTrip.tripId,
        });
        return;
      } else {
        dispatch(bookingAction.reset());
        dispatch(bookingAction.setService(service));
        navigationService.navigate('/book', null);
        return;
      }
    } catch (error: any) {
      alertService.error(error.message);
    }
  };
  return (
    <div
      key={serviceGroup.id}
      className={
        'flex-column medium-border-radius background-white card-shadow layer-1'
      }
    >
      <div
        key={serviceGroup.id}
        className={'text-center main-text h4 bold'}
        style={{ padding: '11px 0px' }}
      >
        {serviceGroup.name}
      </div>

      <div className={'horizontal-separator'} />

      <div
        className={'flex-row wrap'}
        style={{ padding: '13px 15px 24px 15px', rowGap: 12, columnGap: 12 }}
      >
        {serviceGroup.services.map(service => (
          <div
            key={service.id}
            className={'flex-column align-center'}
            style={{ width: 'calc((100% - 36px) / 4)' }}
            onClick={() => onClick(service)}
          >
            <img
              className={'round contain'}
              src={service.image}
              alt={''}
              style={{ height: 40, width: 40 }}
            />
            <p className={'h6 text-center'} style={{ marginTop: 10 }}>
              {service.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
