import Lottie from 'react-lottie';
import Animations from '../assets/animations';
import Images from '../assets/images';

export default function FullPageLoading() {
  return (
    <div
      className={`absolute-full-page page background-main flex-column center`}
      data-testid={'full-page-loading'}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: Animations.loadingWhite,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={160}
        height={160}
      />

      <img
        src={Images.logoWhite}
        alt=""
        className={'contain absolute'}
        style={{ height: 80, width: 80 }}
      />
    </div>
  );
}
