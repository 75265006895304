import ServiceGroup from '../interfaces/entities/ServiceGroup';
import { ConfigDTO } from '../interfaces/dto/config/ConfigDTO';
import { ServiceType } from '../interfaces/entities/ServiceType';
import { createServiceTypeFromDTO } from '../interfaces/dto/config/ServiceTypeDTO';

export default function getServiceGroups(response: ConfigDTO): ServiceGroup[] {
  const groups = response.serviceGroups.map(serviceGroup => {
    return {
      id: serviceGroup.groupId,
      name: serviceGroup.serviceName,
      services: serviceGroup.services.map(service => {
        return {
          id: service.serviceID,
          name: service.serviceName,
          image: service.image,
        };
      }),
    };
  });

  return groups.filter(group => group.services.length > 0);
}

export function getServiceTypes(
  response: ConfigDTO,
  serviceId: number,
): ServiceType[] {
  return response.serviceTypes
    .filter(serviceType => serviceType.serviceID === serviceId)
    .map(dto => createServiceTypeFromDTO(dto));
}
