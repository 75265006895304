import { createContext, PropsWithChildren, useContext } from 'react';
import DataService from '../interfaces/services/DataService';
import NavigationService from '../interfaces/services/NavigationService';
import AuthenticationService from '../interfaces/services/AuthenticationService';
import AlertService from '../interfaces/services/AlertService';
import LocationService from '../interfaces/services/LocationService';
import FavoriteAddressService from '../interfaces/services/FavoriteAddressService';
import TripService from '../interfaces/services/TripService';

const ServiceContext = createContext<{
  dataService?: DataService;
  navigationService?: NavigationService;
  authenticationService?: AuthenticationService;
  alertService?: AlertService;
  locationService?: LocationService;
  favoriteAddressService?: FavoriteAddressService;
  tripService?: TripService;
}>({});

interface ServiceProviderProps extends PropsWithChildren {
  dataService?: DataService;
  navigationService?: NavigationService;
  authenService?: AuthenticationService;
  alertService?: AlertService;
  locationService?: LocationService;
  favoriteAddressService?: FavoriteAddressService;
  tripService?: TripService;
}

export default function ServiceProvider(props: ServiceProviderProps) {
  return (
    <ServiceContext.Provider
      value={{
        dataService: props.dataService,
        navigationService: props.navigationService,
        authenticationService: props.authenService,
        alertService: props.alertService,
        locationService: props.locationService,
        favoriteAddressService: props.favoriteAddressService,
        tripService: props.tripService,
      }}
    >
      {props.children}
    </ServiceContext.Provider>
  );
}

export function useDataService() {
  const { dataService } = useContext(ServiceContext);
  return dataService!;
}

export function useNavigationService() {
  const { navigationService } = useContext(ServiceContext);
  return navigationService!;
}

export function useAuthenService() {
  const { authenticationService } = useContext(ServiceContext);
  return authenticationService!;
}

export function useAlertService() {
  const { alertService } = useContext(ServiceContext);
  return alertService!;
}

export function useLocationService() {
  const { locationService } = useContext(ServiceContext);
  return locationService!;
}

export function useFavoriteAddressService() {
  const { favoriteAddressService } = useContext(ServiceContext);
  return favoriteAddressService!;
}

export function useTripService() {
  const { tripService } = useContext(ServiceContext);
  return tripService!;
}
