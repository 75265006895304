import Coordinate from '../interfaces/entities/Coordinate';

export default function generateDriverLatLngArounds(
  center: Coordinate,
  radius: number,
) {
  let result: Coordinate[] = [];
  const count = Math.floor(Math.random() * 7) + 3;
  for (let i = 0; i < count; i++) {
    var y0 = center.lat;
    var x0 = center.lng;
    var rd = radius / 111300;

    var u = Math.random();
    var v = Math.random();

    var w = rd * Math.sqrt(u);
    var t = 2 * Math.PI * v;
    var x = w * Math.cos(t);
    var y = w * Math.sin(t);

    // var xp = x / Math.cos(y0);
    result.push({
      lat: y + y0,
      lng: x + x0,
    });
  }
  return result;
}
