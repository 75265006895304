import TripService, { TRIP_EVENTS } from '../interfaces/services/TripService';
import SocketService from '../interfaces/services/SocketService';
import Trip from '../interfaces/entities/Trip';
import EventService from './EventService';

export default class SocketTripService implements TripService {
  private socket: SocketService;

  constructor(socket: SocketService) {
    this.socket = socket;
  }

  createTrip(tripID: string) {
    if (!this.socket.isConnected()) {
      this.socket.connect();
    }

    this.socket.emit(TRIP_EVENTS.DO_CREATE_TRIP, tripID);
  }

  cancelFindingTrip() {
    this.socket.emit(TRIP_EVENTS.DO_CANCEL_FINDING_TRIP, null);
    this.socket.disconnect();
  }
  continueTrip(tripID: string) {
    if (!this.socket.isConnected()) {
      this.socket.connect();
    }

    this.socket.emit(TRIP_EVENTS.DO_CONTINUE_TRIP, tripID);
  }
  onFinishedCreateTrip(cb: (info: Trip) => void) {
    return EventService.addListener(TRIP_EVENTS.ON_FINISH_CREATE_TRIP, cb);
  }
  onCreateTripFailed(cb: () => void) {
    return EventService.addListener(TRIP_EVENTS.ON_CREATE_TRIP_FAILED, cb);
  }
  onFinishTrip(cb: () => void) {
    return EventService.addListener(TRIP_EVENTS.ON_FINISH_TRIP, cb);
  }
  onRejectTrip(cb: () => void) {
    return EventService.addListener(TRIP_EVENTS.ON_REJECT_TRIP, cb);
  }
}
