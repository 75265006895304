import { PropsWithChildren, useEffect, useState } from 'react';
import {
  useAuthenService,
  useNavigationService,
} from '../providers/ServicesProvider';

export default function AuthenticationChecker(props: PropsWithChildren) {
  const [finishedChecking, setFinishedChecking] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const authenticationService = useAuthenService();
  const navigationService = useNavigationService();

  useEffect(() => {
    const checkAuth = async () => {
      const isAuthen = await authenticationService.isAuthen();
      if (!isAuthen) {
        navigationService.replace('/home', null);
      } else {
        setIsAuthenticated(true);
      }
      setFinishedChecking(true);
    };

    checkAuth();
  }, []);

  if (!finishedChecking) return null;

  return (
    <div data-testid={'authentication-checker'}>
      {isAuthenticated && props.children}
    </div>
  );
}
