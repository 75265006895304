import DataService from '../interfaces/services/DataService';
import AuthenticationService from '../interfaces/services/AuthenticationService';
import APIService from './APIService';
import ServiceGroup from '../interfaces/entities/ServiceGroup';
import getServiceGroups, { getServiceTypes } from '../parsers/ConfigParser';
import Address from '../interfaces/entities/Address';
import Service from '../interfaces/entities/Service';
import { PaymentType } from '../interfaces/entities/PaymentType';
import TripDetail from '../interfaces/entities/TripDetail';
import {
  getTripID,
  isOnTrip,
} from '../interfaces/dto/response/CheckGoResponse';
import { createEstimateCostRequest } from '../interfaces/dto/request/EstimateCostRequest';
import DoEstimateResponse, {
  getEstimatedCost,
} from '../interfaces/dto/response/DoEstimateResponse';
import { ServiceType } from '../interfaces/entities/ServiceType';
import { ConfigDTO } from '../interfaces/dto/config/ConfigDTO';
import Promotion, {
  existInPromotionList,
} from '../interfaces/entities/Promotion';
import { createPromotionsFromDiscountDTOs } from '../interfaces/dto/response/GetPromotionResponse';
import { createBookTripRequest } from '../interfaces/dto/request/BookTripRequest';
import { createTripDetailFromResponse } from '../interfaces/dto/response/GetGoDetailResponse';
import NavigationService from '../interfaces/services/NavigationService';
import Coordinate from '../interfaces/entities/Coordinate';
import generateDriverLatLngArounds from '../helpers/generateDriverLatLngArounds';
import Profile from '../interfaces/entities/Profile';
import { createProfileFromResponse } from '../interfaces/dto/response/GetProfileResponse';
import getCostAfterDiscount from '../helpers/getCostAfterDiscount';
import { createHistoryFromResponse } from '../interfaces/dto/response/GetHistoryResponse';
import TripHistory from '../interfaces/entities/TripHistory';
import { createServiceTypeFromDTO } from '../interfaces/dto/config/ServiceTypeDTO';

export default class DataApiService implements DataService {
  private loginAccessToken = '';
  private apiService: APIService;
  private config: ConfigDTO | null = null;
  private promotions: Promotion[] = [];
  private previousEstimate: DoEstimateResponse | null = null;
  private promotionsCached: {
    [key: string]: {
      discountType: string;
      discountValue: number;
    };
  } = {};

  constructor(
    authenService: AuthenticationService,
    navigationService: NavigationService,
    baseURL: string,
  ) {
    this.apiService = new APIService(authenService, navigationService, baseURL);
  }

  async loginWithPassword(
    phoneNumber: string,
    password: string,
  ): Promise<{ token: string | null }> {
    try {
      const response = await this.apiService.loginWithPassword(
        phoneNumber,
        password,
      );
      return { token: response.accessToken };
    } catch (error) {
      throw error;
    }
  }

  async requestOTP(phoneNumber: string): Promise<void> {
    try {
      this.loginAccessToken = '';
      const response = await this.apiService.register('', phoneNumber);
      this.loginAccessToken = response.accessToken;
    } catch (error) {
      throw error;
    }
  }

  async verifyOTP(
    phoneNumber: string,
    otp: string,
  ): Promise<{ result: boolean; token: string; firstTimeLogin?: boolean }> {
    try {
      const response = await this.apiService.verifyOTP(
        this.loginAccessToken,
        otp,
      );
      return {
        result: true,
        token: response.accessToken,
        firstTimeLogin: response.isFirstLogin || false,
      };
    } catch (error) {
      throw error;
    }
  }

  async updateUserInfo(
    name: string,
    email: string,
    password: string,
  ): Promise<void> {
    try {
      return await this.apiService.updateProfile(name, email, password);
    } catch (error) {
      throw error;
    }
  }

  async fetchConfig() {
    return await this.apiService.getConfig();
  }

  async getConfig(): Promise<ConfigDTO> {
    if (this.config === null) {
      this.config = await this.fetchConfig();
      return this.config;
    }
    return this.config;
  }

  async getServiceGroups(): Promise<ServiceGroup[]> {
    try {
      const config = await this.getConfig();
      return getServiceGroups(config);
    } catch (error) {
      throw error;
    }
  }

  async getServiceTypes(service: Service): Promise<ServiceType[]> {
    try {
      const config = await this.getConfig();
      return getServiceTypes(config, service.id);
    } catch (error) {
      throw error;
    }
  }

  async getAllServiceTypes(): Promise<ServiceType[]> {
    try {
      const config = await this.getConfig();
      return config.serviceTypes.map(dto => createServiceTypeFromDTO(dto));
    } catch (error) {
      throw error;
    }
  }

  async checkOnTrip(): Promise<{ isOnTrip: boolean; tripId: string | null }> {
    try {
      const response = await this.apiService.doCheckGo();
      if (isOnTrip(response)) {
        return { isOnTrip: true, tripId: getTripID(response) };
      } else {
        return { isOnTrip: false, tripId: null };
      }
    } catch (error) {
      throw error;
    }
  }

  async estimateCost(option: {
    pickup: Address;
    dropOff_1: Address | null;
    dropOff_2: Address | null;
    serviceType: ServiceType;
    paymentType: PaymentType;
    startDate: Date;
    endDate: Date;
  }) {
    try {
      const request = createEstimateCostRequest(option);
      const response = await this.apiService.doEstimateCost(request);
      this.previousEstimate = response;
      return getEstimatedCost(response);
    } catch (error) {
      throw error;
    }
  }

  async getPromotionCodes(
    serviceType: ServiceType,
    pickupAddress: Address,
  ): Promise<Promotion[]> {
    try {
      const getPromotionResponse = await this.apiService.getPromotionCodes(
        serviceType.serviceID,
        pickupAddress.address,
      );
      this.promotions = createPromotionsFromDiscountDTOs(
        getPromotionResponse.discounts,
      );
      return this.promotions;
    } catch (e: any) {
      throw e;
    }
  }

  async validatePromotionCode(promotionCode: string): Promise<boolean> {
    try {
      const isExist = existInPromotionList(promotionCode, this.promotions);
      if (isExist) return isExist;

      // it not exist, try to validate with server
      const response = await this.apiService.getDiscountDetail(promotionCode);
      if (response.discounts) {
        this.promotionsCached[response.discounts.discount_code] = {
          discountType: response.discounts.discountType,
          discountValue: response.discounts.discount_value,
        };
        return true;
      }

      return false;
    } catch (e: any) {
      throw e;
    }
  }

  async suggestAddress(key: string): Promise<Address[]> {
    try {
      const response = await this.apiService.getSuggestAddresses(key);
      return Promise.resolve(
        response.predictions.map(p => ({
          address: p.description,
          location: {
            lat: 0,
            lng: 0,
          },
          placeID: p.place_id,
          name: p.description.split(',')[0],
        })),
      );
    } catch (error) {
      throw error;
    }
  }

  async getCoordinateFromPlaceID(placeID: string): Promise<Coordinate> {
    try {
      const response = await this.apiService.getAddressByPlaceID(placeID);
      return {
        lat: response.latitude,
        lng: response.longitude,
      };
    } catch (e) {
      throw e;
    }
  }

  async book(bookInfo: {
    pickup: Address;
    dropOff_1: Address;
    dropOff_2: Address | null;
    serviceType: ServiceType;
    paymentType: PaymentType;
    promotion: string;
    note: string;
  }): Promise<string> {
    try {
      if (!this.previousEstimate) {
        throw new Error(
          'Chưa đầy đủ thông tin để đặt chuyến, vui lòng kiểm tra lại',
        );
      }

      const request = createBookTripRequest({
        ...bookInfo,
        previousEstimateDTO: this.previousEstimate,
      });

      const response = await this.apiService.book(request);
      return response.res.goID.toString();
    } catch (e) {
      throw e;
    }
  }

  getHotLineNumber() {
    return '19009235';
  }

  getAllHotLineNumbers() {
    return ['0908084499', '19009235'];
  }

  async getTripDetail(tripID: string): Promise<TripDetail> {
    try {
      const response = await this.apiService.getGoDetail(parseInt(tripID));
      return createTripDetailFromResponse(response);
    } catch (error) {
      throw error;
    }
  }

  finishTrip(tripID: string, rate: number, note: string): Promise<void> {
    try {
      return this.apiService.doRate({
        goID: parseInt(tripID),
        note,
        rating: rate,
      });
    } catch (e) {
      throw e;
    }
  }

  async getDriverArounds(center: Coordinate): Promise<Coordinate[]> {
    try {
      const config = await this.getConfig();
      const distance = config?.distanceScopeDriver || 10;
      return generateDriverLatLngArounds(center, distance * 1000);
    } catch (error) {
      throw error;
    }
  }

  async getProfile(): Promise<Profile> {
    try {
      const response = await this.apiService.getProfile();
      return createProfileFromResponse(response);
    } catch (error) {
      throw error;
    }
  }

  async applyDiscount(cost: number, promotion: string): Promise<number> {
    try {
      if (promotion in this.promotionsCached) {
        return getCostAfterDiscount(cost, this.promotionsCached[promotion]);
      }

      const response = await this.apiService.getDiscountDetail(promotion);
      if (response.discounts) {
        this.promotionsCached[response.discounts.discount_code] = {
          discountType: response.discounts.discountType,
          discountValue: response.discounts.discount_value,
        };
        return getCostAfterDiscount(cost, this.promotionsCached[promotion]);
      }

      return cost;
    } catch (error) {
      throw error;
    }
  }

  async getHistory(page: number): Promise<TripHistory[]> {
    try {
      const response = await this.apiService.getHistory(page);
      const serviceTypes = await this.getAllServiceTypes();
      return createHistoryFromResponse(response, serviceTypes);
    } catch (error) {
      throw error;
    }
  }
}
