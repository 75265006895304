export default function getCostAfterDiscount(
  originalCost: number,
  discountRule: {
    discountType: string;
    discountValue: number;
  },
) {
  if (discountRule.discountType === 'RATIO') {
    return originalCost - originalCost * (discountRule.discountValue / 100);
  }

  return originalCost - discountRule.discountValue;
}
