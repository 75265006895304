import SocketService from '../interfaces/services/SocketService';
import AuthenticationService from '../interfaces/services/AuthenticationService';
import { Client, IFrame, IMessage, StompSubscription } from '@stomp/stompjs';
import EventService from './EventService';
import { createTripFromResponse } from '../interfaces/dto/response/OnFollowResponse';
import { TRIP_EVENTS } from '../interfaces/services/TripService';
import { logger } from './Logger';

const EVENTS = {
  doAuthen: '/app/doAuthen',
  onAuthen: 'onAuthen',
  doBook: '/app/doBook',
  onBook: 'onBook',
  doCancelBook: '/app/doCancelBook',
  onFoundDriver: 'onFoundDriver',
  onNotFoundDriver: 'onNotFoundDriver',
  onUpdateLocation: 'onUpdateLocation',
  onFinishGo: 'onFinishGo',
  onRejectGo: 'onRejectGo',
  doFollowGo: '/app/doFollowGo',
  onFollowGo: 'onFollowGo',
  doChat: '/app/doChat',
  onChat: 'onChat',
};

class WebSocketService implements SocketService {
  private readonly url: string;
  private socket: Client | null = null;
  private authenticationService: AuthenticationService;
  private subscribeBook: StompSubscription | null = null;
  private subscribeOnFoundDriver: StompSubscription | null = null;
  private subscribeOnNotFoundDriver: StompSubscription | null = null;
  private subscribeOnFollowGo: StompSubscription | null = null;
  private subscribeOnUpdateLocation: StompSubscription | null = null;
  private subscribeOnFinishGo: StompSubscription | null = null;
  private subscribeOnRejectGo: StompSubscription | null = null;
  private subscribeOnChat: StompSubscription | null = null;

  constructor(url: string, authenticationService: AuthenticationService) {
    this.url = url;
    this.authenticationService = authenticationService;
  }

  prepareSocket() {
    if (!this.socket) return;

    // when connect success => begin authen
    this.socket.onConnect = async (frame: IFrame) => {
      try {
        logger.log('==> socket connected');

        const token = await this.authenticationService.getAuthen();
        const phone = await this.authenticationService.getPhone();

        if (token && phone && this.socket) {
          // listen for authen success
          this.socket.subscribe(`/user/${phone}/onAuthen`, () => {
            logger.log('==> socket authenticated');
            logger.log(`==> phone: ${phone}`);
          });

          // send authen request
          this.socket.publish({
            destination: EVENTS.doAuthen,
            body: JSON.stringify({ type: 1, accessToken: token }),
          });
        }

        // setup all listenters
        this.setUpListener(frame);
      } catch (error) {
        logger.log(error);
      }
    };

    this.socket.onStompError = frame => {
      logger.log('Broker reported error: ' + frame.headers['message']);
      logger.log('Additional details: ' + frame.body);
    };

    this.socket.onDisconnect = () => {
      logger.log('Disconnect');
    };
  }

  async setUpListener(frame: IFrame) {
    try {
      if (!this.socket) return;

      const phone = await this.authenticationService.getPhone();
      const prefix = `/user/${phone}/`;

      if (this.subscribeBook) {
        this.subscribeBook.unsubscribe();
      }
      this.subscribeBook = this.socket.subscribe(
        prefix + EVENTS.onBook,
        response => {
          this._onBook(response);
        },
      );

      if (this.subscribeOnFoundDriver) {
        this.subscribeOnFoundDriver.unsubscribe();
      }
      this.subscribeOnFoundDriver = this.socket.subscribe(
        prefix + EVENTS.onFoundDriver,
        response => {
          this._onFollow(response);
        },
      );

      if (this.subscribeOnNotFoundDriver) {
        this.subscribeOnNotFoundDriver.unsubscribe();
      }
      this.subscribeOnNotFoundDriver = this.socket.subscribe(
        prefix + EVENTS.onNotFoundDriver,
        response => {
          this._onNotFoundDriver(response);
        },
      );

      if (this.subscribeOnFollowGo) {
        this.subscribeOnFollowGo.unsubscribe();
      }
      this.subscribeOnFollowGo = this.socket.subscribe(
        prefix + EVENTS.onFollowGo,
        response => {
          this._onFollow(response);
        },
      );

      if (this.subscribeOnUpdateLocation) {
        this.subscribeOnUpdateLocation.unsubscribe();
      }
      this.subscribeOnUpdateLocation = this.socket.subscribe(
        prefix + EVENTS.onUpdateLocation,
        response => {
          this._onUpdateLocation(response);
        },
      );
      if (this.subscribeOnFinishGo) {
        this.subscribeOnFinishGo.unsubscribe();
      }
      this.subscribeOnFinishGo = this.socket.subscribe(
        prefix + EVENTS.onFinishGo,
        response => {
          this._onFinishGo(response);
        },
      );

      if (this.subscribeOnRejectGo) {
        this.subscribeOnRejectGo.unsubscribe();
      }
      this.subscribeOnRejectGo = this.socket.subscribe(
        prefix + EVENTS.onRejectGo,
        response => {
          this._onRejectGo(response);
        },
      );

      // if (this.subscribeOnChat) {
      //   this.subscribeOnChat.unsubscribe();
      // }
      // this.subscribeOnChat = this.socket.subscribe(
      //   prefix + EVENTS.onChat + '/' + ChatScreen.goID,
      //   response => {
      //     this._onChat(response);
      //   },
      // );
    } catch (error) {}
  }

  _onBook = (response: IMessage) => {
    try {
      const str = response.body;
      const data = JSON.parse(str);
      logger.log('ONBOOK: ' + str);
    } catch (error) {}
  };

  _onFollow = (response: IMessage) => {
    try {
      const str = response.body;
      const data = JSON.parse(str);
      EventService.emit(
        TRIP_EVENTS.ON_FINISH_CREATE_TRIP,
        createTripFromResponse(data),
      );
      logger.log('ON_FOLLOW:' + str);
    } catch (error) {}
  };
  _onNotFoundDriver = (response: IMessage) => {
    try {
      const str = response.body;
      const data = JSON.parse(str);
      EventService.emit(TRIP_EVENTS.ON_CREATE_TRIP_FAILED, data.data);
      logger.log('ON_NOT_FOUND_DRIVER:' + str);
    } catch (error) {}
  };

  _onUpdateLocation = (response: IMessage) => {
    try {
      const str = response.body;
      const data = JSON.parse(str);
      logger.log('ONUPDATELOCATION:' + str);
    } catch (error) {}
  };

  _onFinishGo = (response: IMessage) => {
    try {
      const str = response.body;
      const data = JSON.parse(str);
      EventService.emit(TRIP_EVENTS.ON_FINISH_TRIP, data.data);
      logger.log('ONFINISHGO:' + str);
    } catch (error) {}
  };

  _onRejectGo = (response: IMessage) => {
    try {
      const str = response.body;
      const data = JSON.parse(str);
      EventService.emit(TRIP_EVENTS.ON_REJECT_TRIP, data.data);
      logger.log('ON_REJECT_GO:' + str);
    } catch (error) {}
  };

  _doBook = async (goID: string) => {
    try {
      if (!this.socket) return;

      const accessToken = await this.authenticationService.getAuthen();
      logger.log(`==> doBook: ${goID}, ${accessToken}`);
      this.socket.publish({
        destination: EVENTS.doBook,
        body: JSON.stringify({
          goID: goID,
          accessToken: accessToken,
        }),
      });
    } catch (error) {}
  };

  _doCancelBook = async (goID: string) => {
    try {
      if (!this.socket) return;

      const accessToken = await this.authenticationService.getAuthen();
      logger.log(`==> doCancelBook: ${goID}, ${accessToken}`);
      this.socket.publish({
        destination: EVENTS.doCancelBook,
        body: JSON.stringify({
          goID: goID,
          accessToken: accessToken,
        }),
      });
    } catch (error) {}
  };

  _doFollowGo = async (goID: string) => {
    try {
      if (!this.socket) return;

      const accessToken = await this.authenticationService.getAuthen();
      logger.log(`==> doFollowGo: ${goID}, ${accessToken}`);
      this.socket.publish({
        destination: EVENTS.doFollowGo,
        body: JSON.stringify({
          goID: goID,
          accessToken: accessToken,
        }),
      });
    } catch (error) {}
  };

  // _doChat = async (goID, content) => {
  //   try {
  //     const accessToken = await StorageService.get(KeyStorage.ACCESS_TOKEN);
  //     this.socket.publish({
  //       destination: EVENTS.doChat,
  //       body: JSON.stringify({
  //         goID: goID,
  //         accessToken: accessToken,
  //         type: 1,
  //         content: content,
  //         // driverPhone: "0988128892"
  //       }),
  //     });
  //   } catch (error) {}
  // };
  //
  // _onChat = response => {
  //   try {
  //     logger.log('ON_CHAT:' + JSON.stringify(response));
  //
  //     const str = response.body;
  //     const data = JSON.parse(str);
  //     EventEmitter.emit(Event.ON_CHAT, data.data);
  //   } catch (error) {}
  // };
  //
  // _subscribeOnChat = async goID => {
  //   const phone = await StorageService.get(KeyStorage.PHONE);
  //   const prefix = `/user/${phone}/`;
  //   if (this.subscribeOnChat) {
  //     this.subscribeOnChat.unsubscribe();
  //   }
  //   this.subscribeOnChat = this.socket.subscribe(
  //     prefix + EVENTS.onChat + '/' + goID,
  //     response => {
  //       this._onChat(response);
  //     },
  //   );
  // };

  connect(): void {
    console.log(`==> connect to ${this.url}`);
    this.socket = new Client({
      brokerURL: this.url,
      forceBinaryWSFrames: true,
      appendMissingNULLonIncoming: true,
      debug: function (str) {
        logger.log(str);
      },
      reconnectDelay: 10000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });
    this.prepareSocket();

    this.socket.activate();
  }

  isConnected(): boolean {
    return this.socket !== null && this.socket.connected;
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.deactivate();
      this.socket = null;
    }
  }

  emit(event: string, data: any): void {
    if (!this.socket) return;

    switch (event) {
      case TRIP_EVENTS.DO_CREATE_TRIP:
        this._doBook(data);
        break;
      case TRIP_EVENTS.DO_CANCEL_FINDING_TRIP:
        this._doCancelBook(data);
        break;
      case TRIP_EVENTS.DO_CONTINUE_TRIP:
        this._doFollowGo(data);
        break;
    }
  }
}

export default WebSocketService;
