import RatingPage from '../screens/book/RatingPage';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectDriver } from '../redux/slices/bookingSlice';

export default function RatingNavigationPage() {
  const location = useLocation();
  const tripID = location.state.tripID as string;

  const driver = useSelector(selectDriver);

  if (!driver) {
    return null;
  }

  return <RatingPage driver={driver} tripID={tripID} />;
}
