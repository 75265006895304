import Coordinate from '../entities/Coordinate';

export interface LocationFetcher {
  getCurrentCoordinate: () => Promise<Coordinate | null>;
}

export const ERROR_LOCATION_UNAVAILABLE =
  'Thiết bị không hỗ trợ tính năng vị trí';
export const ERROR_LOCATION_UNAUTHORIZED =
  'Chưa cấp quyền truy cập vị trí cho ứng dụng';
