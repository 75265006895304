import Texts from '../text/texts';
import { useNavigationService } from '../providers/ServicesProvider';

export default function LoginPopup() {
  const navigationService = useNavigationService();

  const goToLogin = () => navigationService.navigate('/login', null);

  return (
    <div
      data-testid={'login-popup'}
      className={
        'sticky-bottom layer-2 flex-column medium-border-radius background-white top-heavy-shadow center medium-border-radius'
      }
      style={{
        padding: '15px 23px',
        gap: 19,
      }}
    >
      <div
        style={{
          background: '#D1CED5',
          height: 5,
          width: 45,
        }}
      />
      <p className={'h5 semibold text-center'}>{Texts.loginPopup.title}</p>
      <button className={'button bold h4'} onClick={goToLogin}>
        {Texts.loginPopup.loginButton}
      </button>
    </div>
  );
}
