import { useState } from 'react';
import Promotion from '../interfaces/entities/Promotion';
import Images from '../assets/images';

export default function PromotionSelectionPopup(props: {
  onFinish: (promotion: string) => void;
  onClose: () => void;
  promotions: Promotion[];
}) {
  const { promotions } = props;
  const [promotion, setPromotion] = useState('');

  const onSubmit = (event: any) => {
    props.onFinish(promotion);
  };

  const disableSubmit = promotion.length === 0;

  return (
    <div
      className={
        'absolute-full-page popup-background-color layer-3 center flex-column'
      }
      data-testid={'dim-background'}
      onClick={() => {
        props.onClose();
      }}
    >
      <div
        className={'flex-column small-border-radius background-white'}
        data-testid="promotion-selection-popup"
        style={{
          padding: '25px 20px',
          gap: 20,
          width: '70%',
        }}
        onClick={(e: any) => e.stopPropagation()}
      >
        <p className={'bold h3 main-text text-center'}>Mã khuyến mãi</p>
        <input
          className={
            'input h4 medium full-width gray-border tiny-border-radius text-center'
          }
          placeholder={'Nhập mã khuyến mãi'}
          style={{ height: 40 }}
          data-testid={'promotion-selection-input'}
          value={promotion}
          onChange={event => setPromotion(event.target.value)}
        />
        <button
          className={`button bold h4 card-shadow ${disableSubmit && 'disabled disabled-color'}`}
          data-testid={'promotion-selection-confirm'}
          onClick={onSubmit}
          disabled={disableSubmit}
        >
          XÁC NHẬN
        </button>

        {promotions.length > 0 && (
          <div data-testid={'promotion-selection-list'}>
            <p className={'bold h3 main-text text-center'}>Mã hiện có</p>
            <div
              className={'horizontal-light-separator'}
              style={{ marginTop: 6 }}
            />
            <div
              className={'flex-column hide-scroll-bar'}
              style={{
                maxHeight: '50vh',
                overflowY: 'scroll',
              }}
            >
              {promotions.map((promotion, index) => (
                <div
                  key={index}
                  className={'flex-row align-center full-width'}
                  style={{ gap: 12 }}
                >
                  <div
                    className={'flex-column round center background-main'}
                    style={{
                      height: 45,
                      width: 45,
                    }}
                  >
                    <img
                      src={promotion.image || Images.giftCard}
                      className={'contain round'}
                      style={{ height: 30, width: 30 }}
                    />
                  </div>

                  <div
                    className={'border-bottom flex-row flex-1'}
                    style={{
                      padding: '10px 0px',
                      gap: 3,
                    }}
                  >
                    <div className={'flex-column flex-1'}>
                      <p className={'bold h4'}>{promotion.title}</p>
                      <p className={'h5'}>{promotion.description}</p>
                    </div>

                    <div
                      data-testid={promotion.code}
                      className={'flex-column'}
                      onClick={e => {
                        e.stopPropagation();
                        setPromotion(promotion.code);
                      }}
                    >
                      <div
                        className={
                          'flex-column center small-border-radius main-border'
                        }
                        style={{
                          padding: '3px',
                        }}
                      >
                        <p className={'main-text'}>Thêm</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
