import { PaymentType } from '../interfaces/entities/PaymentType';
import Images from '../assets/images';

export default function PaymentSelectionPopup(props: {
  onFinish: (newPayment: PaymentType) => void;
  onClose: () => void;
}) {
  return (
    <div
      className={
        'absolute-full-page popup-background-color layer-3 center flex-column'
      }
      data-testid={'dim-background'}
      onClick={props.onClose}
    >
      <div
        data-testid={'payment-selection-popup'}
        className={'flex-column small-border-radius background-white'}
        style={{
          padding: '25px 20px',
          gap: 25,
          width: '70%',
        }}
        onClick={(e: any) => e.stopPropagation()}
      >
        <p className={'bold h3 main-text text-center'}>
          Phương thức thanh toán
        </p>

        <div
          className={'flex-row align-center'}
          style={{
            gap: 15,
            padding: '0px 17px',
          }}
        >
          <img
            src={Images.checked}
            style={{
              height: 22,
              width: 22,
            }}
            className={'contain bold'}
          />

          <p className={'h4'}>Tiền mặt</p>
        </div>
        <button
          className={'button bold h4 card-shadow'}
          data-testid={'payment-selection-confirm'}
          onClick={() => props.onFinish('CASH')}
        >
          XÁC NHẬN
        </button>
      </div>
    </div>
  );
}
