export default function validatePhoneNumber(phoneNumber: string): {
  result: boolean;
  code?: 'EMPTY' | 'INVALID';
} {
  if (phoneNumber.length === 0) {
    return {
      result: false,
      code: 'EMPTY',
    };
  }

  const pattern = /^(\+)?([ 0-9]){9,11}$/g;
  if (pattern.test(phoneNumber)) {
    return { result: true };
  } else {
    return {
      result: false,
      code: 'INVALID',
    };
  }
}
