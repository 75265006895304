import { EventEmitter, EventSubscription } from 'fbemitter';

class SimpleEvent {
  private emitter: EventEmitter;

  constructor() {
    this.emitter = new EventEmitter();
  }

  addListener(
    event: string,
    callback: (...args: any[]) => any,
  ): EventSubscription {
    return this.emitter.addListener(event, callback);
  }

  emit(event: string, data: any) {
    this.emitter.emit(event, data);
  }
}

const EventService = new SimpleEvent();
export default EventService;
