import { useState } from 'react';
import { useDataService } from '../providers/ServicesProvider';

export default function HotlineFloatingButton() {
  const [expand, setExpand] = useState(false);
  const dataService = useDataService();

  const onClick = () => setExpand(true);

  return (
    <div data-testid={'hotline-floating-button'} onClick={onClick}>
      {expand && (
        <div>
          {dataService.getAllHotLineNumbers().map((hotline, index) => (
            <div key={index}>
              <p>{hotline}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
