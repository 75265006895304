import { BookType } from '../interfaces/entities/BookType';

interface AddressDisplay {
  pickup: boolean;
  dropoff1: boolean;
  dropoff2: boolean;
  startDay: boolean;
  endDay: boolean;
}
export default function displayAddressesBaseOnBookingType(
  bookingType: BookType,
): AddressDisplay {
  const displayDropOff1 =
    bookingType === '2_ADDRESS' ||
    bookingType === '2_ADDRESS_1_DAY' ||
    bookingType === '2_ADDRESS_2_DAY';

  const displayDropoff2 = displayDropOff1;

  const displayStartDay =
    bookingType === '1_ADDRESS_1_DAY' ||
    bookingType === '1_ADDRESS_2_DAY' ||
    bookingType === '2_ADDRESS_1_DAY' ||
    bookingType === '2_ADDRESS_2_DAY';
  const displayEndDay =
    bookingType === '1_ADDRESS_2_DAY' || bookingType === '2_ADDRESS_2_DAY';

  return {
    pickup: true,
    dropoff1: displayDropOff1,
    dropoff2: displayDropoff2,
    startDay: displayStartDay,
    endDay: displayEndDay,
  };
}
