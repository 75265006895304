import {
  ERROR_LOCATION_UNAUTHORIZED,
  ERROR_LOCATION_UNAVAILABLE,
  LocationFetcher,
} from '../interfaces/services/LocationFetcher';
import Coordinate from '../interfaces/entities/Coordinate';

export default class GeolocationFetcher implements LocationFetcher {
  public getCurrentCoordinate(): Promise<Coordinate | null> {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject({
          message: ERROR_LOCATION_UNAUTHORIZED,
        });
      }
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        error => {
          if (error.code === error.PERMISSION_DENIED) {
            reject({ message: ERROR_LOCATION_UNAUTHORIZED });
          }
          reject({ message: ERROR_LOCATION_UNAVAILABLE });
        },
      );
    });
  }
}
