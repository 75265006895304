import StarComponent from '../../components/StarComponent';
import Driver from '../../interfaces/entities/Driver';
import { useState } from 'react';
import {
  useAlertService,
  useDataService,
  useNavigationService,
} from '../../providers/ServicesProvider';
import Header from '../../components/Header';

export default function RatingPage(props: { driver: Driver; tripID: string }) {
  const { driver, tripID } = props;
  const [star, setStar] = useState(3);
  const [note, setNote] = useState('');
  const dataService = useDataService();
  const navigationService = useNavigationService();
  const alertService = useAlertService();

  const onSubmit = async () => {
    try {
      await dataService.finishTrip(tripID, star, note);
      navigationService.replace('/', null);
    } catch (error: any) {
      alertService.error(error.message);
    }
  };

  return (
    <div className={'page'}>
      <Header title={'Đánh giá'} />

      <div
        className="flex-column center"
        style={{
          gap: 12,
        }}
      >
        <img
          src={driver.avatar}
          alt={driver.name}
          style={{ width: 100, height: 100 }}
          className="round"
        />
        <p className="bold h4">{driver.name}</p>
      </div>

      <div
        className="flex-column center"
        style={{
          gap: 12,
          margin: '12px 0',
        }}
      >
        <StarComponent star={star} onUpdate={newStar => setStar(newStar)} />
      </div>

      <div className="flex-1" style={{ padding: '0px 24px' }}>
        <textarea
          placeholder="Đánh giá của bạn"
          style={{ padding: 12 }}
          className="full-width input background-gray small-border-radius"
          data-testid={'note'}
          onChange={event => setNote(event.target.value)}
        />
      </div>

      <div
        data-testid={'submit-button'}
        onClick={onSubmit}
        className="background-main white-text bold flex-row center sticky-bottom"
        style={{ padding: '10px 20px', marginTop: 12 }}
      >
        <p>Xác nhận</p>
      </div>
    </div>
  );
}
