import Header from '../../components/Header';
import React, { useEffect, useState } from 'react';
import Address from '../../interfaces/entities/Address';
import {
  useAlertService,
  useDataService,
  useFavoriteAddressService,
  useNavigationService,
} from '../../providers/ServicesProvider';
import { useDebounce } from 'use-debounce';
import { useDispatch } from 'react-redux';
import { bookingAction } from '../../redux/slices/bookingSlice';

function Item(props: {
  address: Address;
  index: number;
  testID: string;
  onClick: (address: Address) => void;
  onDeleteAddress?: (address: Address) => void;
}) {
  const { address, onDeleteAddress, index } = props;
  return (
    <div
      className={'flex-row align-center'}
      data-testid={props.testID}
      onClick={() => props.onClick(address)}
      key={address.placeID + index}
      style={{
        padding: '0px 20px',
      }}
    >
      <div className="flex-1 flex-column">
        <p className={'flex-1 h4'}>{address.name}</p>
        <p className={'flex-1 h5 gray-text'}>{address.address}</p>
      </div>
      {onDeleteAddress && (
        <p
          data-testid={'delete-favorited-address'}
          className={'main-text bold'}
          style={{ padding: 20 }}
          onClick={event => {
            event.stopPropagation();
            onDeleteAddress(address);
          }}
        >
          Xóa
        </p>
      )}
    </div>
  );
}

export type PickAddressType = 'DROPOFF_1' | 'DROPOFF_2' | 'PICKUP';
export default function PickAddressPage(props: { type: PickAddressType }) {
  const dataService = useDataService();
  const alertService = useAlertService();
  const favoritesService = useFavoriteAddressService();
  const navigationService = useNavigationService();

  const [suggestionAddresses, setSuggestionAddresses] = useState<Address[]>([]);
  const [favoritedAddresses, setFavoritedAddresses] = useState<Address[]>([]);
  const [_searchKey, _setSearchKey] = useState('');
  const [searchKey] = useDebounce(_searchKey, 1000);
  const dispatch = useDispatch();

  const loadSuggestions = async (searchKey: string) => {
    try {
      const result = await dataService.suggestAddress(searchKey);
      setSuggestionAddresses(result);
    } catch (error: any) {
      alertService.error(error.message);
    }
  };

  const dispatchSetAddress = (address: Address) => {
    if (props.type === 'DROPOFF_1') {
      dispatch(bookingAction.setDropOffAddress1(address));
    } else if (props.type === 'DROPOFF_2') {
      dispatch(bookingAction.setDropOffAddress2(address));
    } else if (props.type === 'PICKUP') {
      dispatch(bookingAction.setPickupAddress(address));
    }
  };

  const clickOnSuggestion = async (address: Address) => {
    try {
      const coordinate = await dataService.getCoordinateFromPlaceID(
        address.placeID,
      );
      address.location = coordinate;
      dispatchSetAddress(address);
      await favoritesService.add(address);
      navigationService.back();
    } catch (error: any) {
      alertService.error(error.message);
    }
  };

  const clickOnFavorite = async (address: Address) => {
    dispatchSetAddress(address);
    navigationService.back();
  };

  const onDeleteAddress = async (address: Address) => {
    setFavoritedAddresses(
      favoritedAddresses.filter(
        favorite => favorite.placeID !== address.placeID,
      ),
    );
    await favoritesService.remove(address);
  };

  useEffect(() => {
    if (searchKey.length > 0) {
      loadSuggestions(searchKey);
    }
  }, [searchKey]);

  const loadFavoritedAddresses = async () => {
    try {
      const result = await favoritesService.getAll();
      setFavoritedAddresses(result);
    } catch (error: any) {
      alertService.error(error.message);
    }
  };

  useEffect(() => {
    loadFavoritedAddresses();
  }, []);

  return (
    <div className={'page'}>
      <Header title={'Địa chỉ'} />
      <div
        className={'flex-column'}
        style={{
          padding: '0px 35px',
        }}
      >
        <input
          className={'input gray-border large-border-radius'}
          style={{ padding: '12px 21px' }}
          placeholder={'Nhập địa chỉ'}
          data-testid={'address-search-input'}
          onChange={event => _setSearchKey(event.target.value)}
        />
      </div>

      <div
        className={'horizontal-light-separator'}
        style={{ marginTop: 18, height: 6 }}
      />

      <div className={'flex-column flex-1 scrollable'}>
        <div
          data-testid={'favorited-address-list'}
          style={{
            gap: 20,
            padding: favoritedAddresses.length !== 0 ? '20px 0px' : '',
          }}
        >
          {favoritedAddresses.length !== 0 && (
            <p className={'bold h4'} style={{ padding: '10px 20px' }}>
              Địa chỉ thường đến
            </p>
          )}
          {favoritedAddresses.map((address, index) => {
            return (
              <Item
                address={address}
                index={index}
                testID={'favorited-address'}
                onClick={() => clickOnFavorite(address)}
                onDeleteAddress={() => onDeleteAddress(address)}
              />
            );
          })}
        </div>

        <div
          data-testid={'suggestion-address-list'}
          className={'flex-column'}
          style={{
            padding: '20px 0px',
            gap: 20,
          }}
        >
          {suggestionAddresses.length !== 0 && (
            <p className={'bold h4'} style={{ padding: '10px 20px' }}>
              Địa chỉ gợi ý
            </p>
          )}
          {suggestionAddresses.map((address, index) => {
            return (
              <Item
                address={address}
                index={index}
                testID={'suggestion-address'}
                onClick={() => clickOnSuggestion(address)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
