export default class LocalStorageService {
  async get(key: string) {
    const value = localStorage.getItem(key);
    if (value == '') return value;

    return value || null;
  }

  async set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  async delete(key: string) {
    localStorage.removeItem(key);
  }
}
