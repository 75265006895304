import Address from '../../interfaces/entities/Address';
import { PaymentType } from '../../interfaces/entities/PaymentType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/createStore';
import Service from '../../interfaces/entities/Service';
import Driver from '../../interfaces/entities/Driver';
import Vehicle from '../../interfaces/entities/Vehicle';
import { ServiceType } from '../../interfaces/entities/ServiceType';

interface BookingSliceState {
  bookID: string;
  service: Service | null;
  selectedServiceType: ServiceType | null;
  serviceTypes: ServiceType[];
  pickupAddress: Address | null;
  dropOffAddress1: Address | null;
  dropOffAddress2: Address | null;
  startDay: Date;
  endDay: Date;
  paymentType: PaymentType;
  note: string;
  promotion: string;
  cost: number | null;
  oldCost: number | null;
  driver: Driver | null;
  vehicle: Vehicle | null;
}

const initialState: BookingSliceState = {
  bookID: '',
  service: null,
  selectedServiceType: null,
  serviceTypes: [],
  pickupAddress: null,
  dropOffAddress1: null,
  dropOffAddress2: null,
  startDay: new Date(),
  endDay: new Date(),
  paymentType: 'CASH',
  note: '',
  promotion: '',
  cost: null,
  oldCost: null,
  driver: null,
  vehicle: null,
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    reset: state => {
      return initialState;
    },
    setService: (state, action: PayloadAction<Service>) => {
      state.service = action.payload;
    },
    setServiceTypes: (state, action: PayloadAction<ServiceType[]>) => {
      state.serviceTypes = action.payload;
    },
    setSelectedServiceType: (state, action: PayloadAction<ServiceType>) => {
      state.selectedServiceType = action.payload;
    },
    setPickupAddress: (state, action: PayloadAction<Address>) => {
      state.pickupAddress = action.payload;
    },
    setDropOffAddress1: (state, action: PayloadAction<Address>) => {
      state.dropOffAddress1 = action.payload;
    },
    setDropOffAddress2: (state, action: PayloadAction<Address | null>) => {
      state.dropOffAddress2 = action.payload;
    },
    setStartDay: (state, action: PayloadAction<Date>) => {
      state.startDay = action.payload;
    },
    setEndDay: (state, action: PayloadAction<Date>) => {
      state.endDay = action.payload;
    },
    setPaymentType: (state, action: PayloadAction<PaymentType>) => {
      state.paymentType = action.payload;
    },
    setPromotion: (state, action: PayloadAction<string>) => {
      state.promotion = action.payload;
    },
    setNote: (state, action: PayloadAction<string>) => {
      state.note = action.payload;
    },
    setCost: (state, action: PayloadAction<number | null>) => {
      state.cost = action.payload;
    },
    setOldCost: (state, action: PayloadAction<number | null>) => {
      state.oldCost = action.payload;
    },
    setBookID: (state, action: PayloadAction<string>) => {
      state.bookID = action.payload;
    },
    setDriver: (state, action: PayloadAction<Driver>) => {
      state.driver = action.payload;
    },
    setVehicle: (state, action: PayloadAction<Vehicle>) => {
      state.vehicle = action.payload;
    },
  },
});

export const selectService = (state: RootState) => state.booking.service;
export const selectServiceTypes = (state: RootState) =>
  state.booking.serviceTypes;
export const selectSelectedServiceType = (state: RootState) =>
  state.booking.selectedServiceType;
export const selectPickupAddress = (state: RootState) =>
  state.booking.pickupAddress;
export const selectDropOffAddress1 = (state: RootState) =>
  state.booking.dropOffAddress1;
export const selectDropOffAddress2 = (state: RootState) =>
  state.booking.dropOffAddress2;
export const selectPaymentType = (state: RootState) =>
  state.booking.paymentType;
export const selectPromotion = (state: RootState) => state.booking.promotion;
export const selectNote = (state: RootState) => state.booking.note;
export const selectCost = (state: RootState) => state.booking.cost;
export const selectBookID = (state: RootState) => state.booking.bookID;
export const selectDriver = (state: RootState) => state.booking.driver;
export const selectVehicle = (state: RootState) => state.booking.vehicle;
export const selectStartDay = (state: RootState) => state.booking.startDay;
export const selectEndDay = (state: RootState) => state.booking.endDay;
export const selectOldCost = (state: RootState) => state.booking.oldCost;

export const bookingAction = bookingSlice.actions;

export default bookingSlice.reducer;
