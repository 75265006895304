import Trip from '../../entities/Trip';

export default interface onFollowResponse {
  error_message: ErrorMessage;
  code: number;
  data: Data;
}

export interface ErrorMessage {}

export interface Data {
  res: Res;
}

export interface Res {
  goID: number;
  serviceDetailID: number;
  timeStamp: number;
  pickupAddress: string;
  dropAddress: string;
  dropSecondAddress: any;
  pickupDate: number;
  dropDate: number;
  rating: number;
  originalCost: number;
  discountCost: number;
  giftCode: any;
  cost: number;
  name: string;
  phone: string;
  avatarImg: string;
  servicesOption: any[];
  process: number;
  pickupPlaceID: string;
  dropPlaceID: string;
  dropSecondPlaceID: any;
  mapData: string;
  driverId: number;
  go_type: number;
  discountFromCode: number;
  pickup_latitude: number;
  pickup_longitude: number;
  drop_latitude: number;
  drop_longitude: number;
  drop_second_latitude: number;
  drop_second_longitude: number;
  distance: number;
  distance_text: string;
  payment_status: any;
  delivery_go_info: any;
}

export function createTripFromResponse(response: onFollowResponse): Trip {
  return { id: response.data.res.goID.toString() };
}
