import Images from '../assets/images';
import { Fragment } from 'react';

export default function WaveIndicator(props: { active: boolean }) {
  return (
    <Fragment>
      {props.active && (
        <div
          className={'pulsating-circle round absolute'}
          style={{
            height: 140,
            width: 140,
          }}
        />
      )}
      <img
        src={Images.logoWhite}
        style={{
          height: 140,
          width: 140,
          position: 'absolute',
          left: '50%',
          top: '30%',
          transform: 'translateX(-50%) translateY(-50%)',
        }}
        className={'contain round background-main'}
      />
    </Fragment>
  );
}
