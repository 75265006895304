import Images from '../assets/images';
import React from 'react';
import { useNavigationService } from '../providers/ServicesProvider';

export default function Header(props: { title: string; showBack?: boolean }) {
  const { showBack = true } = props;
  const navigationService = useNavigationService();
  const goBack = () => navigationService.back();
  return (
    <div className={'flex-column center default-padding background-white'}>
      {showBack && (
        <img
          style={{ height: 16, width: 16, left: 26 }}
          className={'icon-button absolute-left'}
          src={Images.back}
          alt={''}
          data-testid={'header-back'}
          onClick={goBack}
        />
      )}
      <p className={'h1 bold'}>{props.title}</p>
    </div>
  );
}
