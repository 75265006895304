import LocationService from '../interfaces/services/LocationService';
import { LocationFetcher } from '../interfaces/services/LocationFetcher';
import { AddressParser } from '../interfaces/services/AddressParser';
import Address from '../interfaces/entities/Address';

export default class GeoLocationService implements LocationService {
  private locationFetcher: LocationFetcher;
  private addressParser: AddressParser;

  constructor(fetcher: LocationFetcher, addressParser: AddressParser) {
    this.locationFetcher = fetcher;
    this.addressParser = addressParser;
  }

  async getCurrentAddress(): Promise<Address | null> {
    try {
      const location = await this.locationFetcher.getCurrentCoordinate();

      if (location) {
        return await this.addressParser.getAddress(location);
      }

      return null;
    } catch (error) {
      throw error;
    }
  }

  async getCurrentCoordinate() {
    return await this.locationFetcher.getCurrentCoordinate();
  }
}
