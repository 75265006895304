import { AddressParser } from '../interfaces/services/AddressParser';
import Address from '../interfaces/entities/Address';
import Coordinate from '../interfaces/entities/Coordinate';
import APIService from './APIService';
import AuthenticationService from '../interfaces/services/AuthenticationService';
import NavigationService from '../interfaces/services/NavigationService';

export default class APIAddressParser implements AddressParser {
  private apiService: APIService;
  constructor(
    authenService: AuthenticationService,
    navigationService: NavigationService,
    baseURL: string,
  ) {
    this.apiService = new APIService(authenService, navigationService, baseURL);
  }

  async getAddress(coordinate: Coordinate): Promise<Address> {
    try {
      const response = await this.apiService.getAddressByLatLng(
        coordinate.lat,
        coordinate.lng,
      );
      const { place } = response;
      return {
        address: place.placeAddress,
        location: {
          lat: place.latitude,
          lng: place.longitude,
        },
        placeID: place.placeID,
        name: place.placeAddress,
      };
    } catch (e) {
      throw e;
    }
  }
}
