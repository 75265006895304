import React, { useEffect, useRef, useState } from 'react';
import { GoogleApiWrapper, Map, Marker, Polyline } from 'google-maps-react';
import Images from '../assets/images';
import { CONFIG } from '../Config.ts';
import { useDataService } from '../providers/ServicesProvider';

function MapComponent(props) {
  const { pickupAddress, dropOffAddress1, dropOffAddress2, google } = props;
  const mapRef = useRef(null);
  const [pathPickupToDropOff1, setPathPickupToDropOff1] = useState([]);
  const [pathDropOff1ToDropOff2, setPathDropOff1ToDropOff2] = useState([]);
  const [mapIsReady, setMapIsReady] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const dataService = useDataService();

  const getPath = async (origin, destination) => {
    return new Promise((resolve, reject) => {
      const directionsService = new google.maps.DirectionsService();

      // draw path between pickup and dropoff 1
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            const points = result.routes[0].overview_path.map(point => ({
              lat: point.lat(),
              lng: point.lng(),
            }));
            resolve(points);
          } else {
            reject(`error fetching directions ${result}`);
          }
        },
      );
    });
  };

  const fetchDrivers = async () => {
    try {
      const response = await dataService.getDriverArounds(
        pickupAddress.location,
      );
      setDrivers(response);
    } catch (error) {
      console.error('error fetching drivers', error);
    }
  };

  // zoom camera when locations changed
  useEffect(() => {
    if (!mapIsReady) return;

    if (dropOffAddress1) {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(pickupAddress.location);
      bounds.extend(dropOffAddress1.location);

      if (dropOffAddress2) {
        bounds.extend(dropOffAddress2.location);
      }

      if (mapRef.current) {
        mapRef.current.fitBounds(bounds, 100);
      }
    }
  }, [pickupAddress, dropOffAddress1, dropOffAddress2, mapIsReady]);

  // draw path when location changed
  useEffect(() => {
    if (!mapIsReady) return;
    if (!mapRef.current) return;
    if (dropOffAddress1) {
      // draw path between pickup and dropoff 1
      getPath(pickupAddress.location, dropOffAddress1.location).then(path =>
        setPathPickupToDropOff1(path),
      );

      if (dropOffAddress2) {
        getPath(dropOffAddress1.location, dropOffAddress2.location).then(path =>
          setPathDropOff1ToDropOff2(path),
        );
      }
    }
  }, [pickupAddress, dropOffAddress1, dropOffAddress2, mapIsReady]);

  // fetch driver on default
  useEffect(() => {
    fetchDrivers();
  }, []);

  if (!google) return null;

  return (
    <Map
      google={props.google}
      initialCenter={pickupAddress.location}
      zoom={16.7}
      fullscreenControl={false}
      mapTypeControl={false}
      // onDragstart={props.onDragStart}
      // onDragend={props.onDragEnd}
      onReady={(props, map) => {
        mapRef.current = map;
        setMapIsReady(true);
      }}
    >
      <Marker
        position={pickupAddress.location}
        data-testid={'pickup-point'}
        icon={{
          url: Images.pickup,
          scaledSize: new props.google.maps.Size(32, 32),
        }}
      />
      {dropOffAddress1 && (
        <Marker
          position={dropOffAddress1.location}
          data-testid={'drop-off-point-1'}
          icon={{
            url: dropOffAddress2 ? Images.dropoff_1 : Images.dropoff,
            scaledSize: new props.google.maps.Size(32, 32),
          }}
        />
      )}
      {dropOffAddress2 && (
        <Marker
          position={dropOffAddress2.location}
          data-testid={'drop-off-point-2'}
          icon={{
            url: Images.dropoff_2,
            scaledSize: new props.google.maps.Size(32, 32),
          }}
        />
      )}
      {dropOffAddress1 && pathPickupToDropOff1.length > 0 ? (
        <Polyline
          path={pathPickupToDropOff1}
          strokeColor={'#ff5d18'}
          strokeOpacity={0.8}
          strokeWeight={4}
        />
      ) : null}
      {dropOffAddress2 && pathDropOff1ToDropOff2.length > 0 ? (
        <Polyline
          path={pathDropOff1ToDropOff2}
          strokeColor={'#ff5d18'}
          strokeOpacity={0.8}
          strokeWeight={4}
        />
      ) : null}
      {drivers.map((driver, index) => (
        <Marker
          key={index}
          position={driver}
          icon={{
            url: Images.driver,
            scaledSize: new props.google.maps.Size(20, 32),
          }}
        />
      ))}
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: CONFIG.GOOGLE_KEY,
  language: 'vn',
})(MapComponent);
