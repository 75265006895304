import { configureStore } from '@reduxjs/toolkit';
import bookingReducer from '../slices/bookingSlice';

export const createStore = () =>
  configureStore({
    reducer: {
      booking: bookingReducer,
    },
  });

const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
export default store;
