import AlertService from '../interfaces/services/AlertService';
import { Bounce, toast } from 'react-toastify';

export default class AlertNotifyService implements AlertService {
  error(message: string) {
    toast.error(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    });
  }

  warning(message: string) {
    toast.warning(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    });
  }
}
