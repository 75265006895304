const Images = {
  logo: require('./images/logo.jpg'),
  logoWhite: require('./images/logo_white.png'),
  back: require('./images/back.png'),
  phone: require('./images/phone.png'),
  key: require('./images/key.png'),
  username: require('./images/username.png'),
  home: require('./images/home.png'),
  history: require('./images/history.png'),
  notify: require('./images/notify.png'),
  account: require('./images/account.png'),
  homeActive: require('./images/home-active.png'),
  historyActive: require('./images/history-active.png'),
  notifyActive: require('./images/notify-active.png'),
  accountActive: require('./images/account-active.png'),
  plus: require('./images/plus.png'),
  info: require('./images/info.png'),
  checked: require('./images/checked.png'),
  pickup: require('./images/pickup.png'),
  dropoff: require('./images/dropoff.png'),
  dropoff_1: require('./images/dropoff_1.png'),
  dropoff_2: require('./images/dropoff_2.png'),
  cost: require('./images/cost.png'),
  message: require('./images/message.png'),
  call: require('./images/call.png'),
  activeStar: require('./images/active-star.png'),
  inactiveStar: require('./images/inactive-star.png'),
  giftCard: require('./images/gift_card.png'),
  driver: require('./images/driver.png'),
  profile: require('./images/profile.png'),
};

export default Images;
