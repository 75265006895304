import ServiceProvider from '../providers/ServicesProvider';
import StorageAuthService from '../services/StorageAuthService';
import AlertNotifyService from '../services/AlertNotifyService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigation, router } from '../navigation/router';
import NavigationRouterService from '../services/NavigationRouterService';
import GeoLocationService from '../services/GeoLocationService';
import StorageFavoriteAddressService from '../services/StorageFavoriteAddressService';
import { Provider } from 'react-redux';
import store from '../redux/store/createStore';
import SocketTripService from '../services/SocketTripService';
import LocalStorageService from '../services/LocalStorageService';
import DataApiService from '../services/DataApiService';
import WebSocketService from '../services/WebSocketService';
import GeolocationFetcher from '../services/GeolocationFetcher';
import APIAddressParser from '../services/APIAddressParser';
import { CONFIG } from '../Config';
import FakeLocationFetcher from '../mocks/FakeLocationFetcher';
import FakeDataService from '../mocks/FakeDataService';

export default function Main() {
  const navigationService = new NavigationRouterService(router.navigate);

  const storageService = new LocalStorageService();
  const authenService = new StorageAuthService(storageService);
  const dataService = new DataApiService(
    authenService,
    navigationService,
    CONFIG.API_URL,
  );
  // const dataService = new FakeDataService();
  const alertService = new AlertNotifyService();

  const locationService = new GeoLocationService(
    new GeolocationFetcher(),
    new APIAddressParser(authenService, navigationService, CONFIG.API_URL),
  );

  const favoriteAddressService = new StorageFavoriteAddressService(
    storageService,
  );

  const websocketService = new WebSocketService(
    CONFIG.SOCKET_URL,
    authenService,
  );
  const tripService = new SocketTripService(websocketService);

  return (
    <ServiceProvider
      dataService={dataService}
      authenService={authenService}
      navigationService={navigationService}
      alertService={alertService}
      locationService={locationService}
      favoriteAddressService={favoriteAddressService}
      tripService={tripService}
    >
      <Provider store={store}>
        <Navigation />
      </Provider>
      <ToastContainer />
    </ServiceProvider>
  );
}
