import AuthenticationService from '../interfaces/services/AuthenticationService';
import { ConfigDTO } from '../interfaces/dto/config/ConfigDTO';
import CheckGoResponse from '../interfaces/dto/response/CheckGoResponse';
import EstimateCostRequest from '../interfaces/dto/request/EstimateCostRequest';
import GetPromotionResponse from '../interfaces/dto/response/GetPromotionResponse';
import DoEstimateResponse from '../interfaces/dto/response/DoEstimateResponse';
import BookTripRequest from '../interfaces/dto/request/BookTripRequest';
import { GetGoDetailResponse } from '../interfaces/dto/response/GetGoDetailResponse';
import NavigationService from '../interfaces/services/NavigationService';
import { logger } from './Logger';
import { GetprofileResponse } from '../interfaces/dto/response/GetProfileResponse';
import TripHistory from '../interfaces/entities/TripHistory';
import { GetHistoryResponse } from '../interfaces/dto/response/GetHistoryResponse';

export default class APIService {
  private authenService: AuthenticationService;
  private navigationService: NavigationService;
  private BASE_URL: string;
  private config: ConfigDTO | null = null;

  constructor(
    authenService: AuthenticationService,
    navigationService: NavigationService,
    baseURL: string,
  ) {
    this.authenService = authenService;
    this.navigationService = navigationService;
    this.BASE_URL = baseURL;
  }

  private queryParams(params: Record<string, any>): string {
    return Object.keys(params)
      .filter(k => params[k] !== null && params[k] !== undefined) // Loại bỏ các items có giá trị null hoặc undefined
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
  }

  private get = async (
    func: string,
    params: Record<any, any> = {},
  ): Promise<Request> => {
    const query = this.queryParams(params);
    let url = this.BASE_URL + func;

    if (query) {
      url += '?' + query;
    }
    const token = await this.authenService.getAuthen();

    logger.log('==========================');
    logger.log(`CALL API: GET - ${func}`);
    logger.log(url);
    logger.log(params);
    logger.log(`token: ${token}`);
    logger.log('==========================');

    return new Request(url, {
      method: 'GET',
      headers: {
        token: token || '',
      },
    });
  };

  private post = async (
    func: string,
    cmd: string,
    params: object = {},
    useDefaultParams = true,
  ): Promise<Request> => {
    const url = this.BASE_URL + func;
    const defaultParams = await this.getDefaultParams();
    const body = {
      cmd,
      data: { ...(useDefaultParams ? defaultParams : {}), ...params },
    };
    const token = await this.authenService.getAuthen();

    logger.log('==========================');
    logger.log(`CALL API: POST - ${cmd}`);
    logger.log(url);
    logger.log(body.data);
    logger.log(`token: ${token}`);
    logger.log('==========================');

    return new Request(url, {
      method: 'POST',
      body: JSON.stringify(body, null, 2),
      headers: {
        'Content-Type': 'application/json',
        token: token || '',
      },
    });
  };

  private execute = async (func: string, request: Request): Promise<any> => {
    try {
      const responseJson = await fetch(request).then(response => {
        return response.json();
      });

      logger.log('==========================');
      logger.log(`RESPONSE API: ${request.method} - ${func}`);
      logger.log(request.url);
      logger.log(responseJson);
      logger.log('==========================');

      if (responseJson.code == 1) {
        if (responseJson.data) {
          return responseJson.data;
        }
      }

      if (responseJson.code == -1 || responseJson.code === 0) {
        if (await this.authenService.isAuthen()) {
          // Xoá token khi hết phiên đăng nhập
          await this.authenService.clearAuthen();

          // Chuyển hướng về trang đăng nhập
          this.navigationService.replace('/login', null);

          throw {
            statusCode: responseJson.code,
            message: responseJson.error_message,
          };
        }
      }

      throw {
        statusCode: responseJson.code,
        message: responseJson.error_message,
      };
    } catch (error: any) {
      if (error.statusCode !== undefined && error.message) {
        throw error;
      }

      throw {
        statusCode: -999,
        message: 'Có lỗi xảy ra vui lòng thử lại',
      };
    }
  };

  private getDefaultParams = async () => {
    var params: any = {};
    params.accessToken = await this.authenService.getAuthen();
    params.platform = 3;
    params.deviceID = navigator.userAgent;
    params.deviceInfo = 'webview';
    params.token = '1';
    params.countryCode = '84';
    params.email = '1';
    params.clientVersion = 1;
    return params;
  };

  async register(
    name: string,
    phone: string,
  ): Promise<{
    accessToken: string;
  }> {
    try {
      const cmd = 'doRegister';
      const request = await this.post('ButlAppServlet/user/services', cmd, {
        name,
        phone,
        password: '123456',
      });

      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }

  async verifyOTP(
    accessToken: string,
    otp: string,
  ): Promise<{
    isFirstLogin?: boolean;
    accessToken: string;
  }> {
    try {
      const cmd = 'doVerifyOTP';
      const request = await this.post('ButlAppServlet/user/services', cmd, {
        accessToken,
        otp,
      });

      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }

  async loginWithPassword(
    phone: string,
    password: string,
  ): Promise<{
    accessToken: string;
  }> {
    try {
      const cmd = 'doLoginWithPassword';
      const request = await this.post('ButlAppServlet/user/services', cmd, {
        phone,
        password,
      });
      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }

  async updateProfile(
    name: string,
    email: string,
    password: string,
  ): Promise<any> {
    try {
      const cmd = 'doUpdateProfile';
      const request = await this.post('ButlAppServlet/user/services', cmd, {
        name,
        email,
        password,
      });

      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }

  async getConfig(): Promise<ConfigDTO> {
    if (this.config) {
      return this.config;
    }

    try {
      const cmd = 'doGetConfig';
      const request = await this.post('ButlAppServlet/app/services', cmd, {});

      const response = await this.execute(cmd, request);
      const config = response.config;
      this.config = config;
      return config;
    } catch (error) {
      throw error;
    }
  }

  async doCheckGo(): Promise<CheckGoResponse> {
    try {
      const cmd = 'doCheckGo';
      const request = await this.post('ButlAppServlet/user/services/', cmd);

      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }

  async doEstimateCost(data: EstimateCostRequest): Promise<DoEstimateResponse> {
    try {
      const cmd = 'doEstimateCost';
      const request = await this.post(
        'ButlAppServlet/user/services/',
        cmd,
        data,
      );
      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }

  async getPromotionCodes(
    serviceDetailId: number,
    pickupAddress: string,
  ): Promise<GetPromotionResponse> {
    try {
      const cmd = 'doGetDiscount';
      const request = await this.post('ButlAppServlet/user/services', cmd, {
        serviceDetailId,
        pickupAddress,
      });
      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }

  async book(data: BookTripRequest): Promise<{
    res: {
      goID: number;
    };
  }> {
    try {
      const cmd = 'doBook';
      const request = await this.post(
        'ButlAppServlet/user/services',
        cmd,
        data,
      );
      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }

  async getGoDetail(goID: number): Promise<GetGoDetailResponse> {
    try {
      const cmd = 'getGoDetail';
      const request = await this.post(
        'ButlAppServlet/user/services',
        cmd,
        {
          goId: goID,
        },
        false,
      );
      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }

  async doRate(params: {
    goID: number;
    note: string;
    rating: number;
  }): Promise<void> {
    try {
      const cmd = 'doRate';
      const request = await this.post(
        'ButlAppServlet/user/services',
        cmd,
        params,
      );
      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }

  async getAddressByLatLng(
    lat: number,
    lng: number,
  ): Promise<{
    place: {
      placeID: string;
      placeAddress: string;
      latitude: number;
      longitude: number;
    };
  }> {
    try {
      const cmd = 'doGetPlace';
      const request = await this.post('ButlAppServlet/user/services', cmd, {
        latitude: lat,
        longitude: lng,
      });
      return await this.execute('doGetPlace', request);
    } catch (e) {
      throw e;
    }
  }

  async getSuggestAddresses(searchKey: string): Promise<{
    predictions: {
      description: string;
      place_id: string;
    }[];
  }> {
    try {
      const cmd = 'doGetAddress';
      const request = await this.post('ButlAppServlet/user/services', cmd, {
        address: encodeURIComponent(searchKey),
      });
      const repsonse = await this.execute(cmd, request);
      return JSON.parse(repsonse.mapData);
    } catch (error) {
      throw error;
    }
  }

  async getAddressByPlaceID(placeID: string): Promise<{
    latitude: number;
    longitude: number;
  }> {
    try {
      const cmd = 'getLocationAddress';
      const request = await this.post('ButlAppServlet/user/services', cmd, {
        place_id: placeID,
      });
      const response = await this.execute(cmd, request);
      const mapData = JSON.parse(response.mapData);
      const data = mapData.result;
      return {
        latitude: data.geometry.location.lat,
        longitude: data.geometry.location.lng,
      };
    } catch (error) {
      throw error;
    }
  }

  async getProfile(): Promise<GetprofileResponse> {
    try {
      const cmd = 'doGetProfile';
      const request = await this.post('ButlAppServlet/user/services', cmd, {});
      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }

  async getDiscountDetail(code: string): Promise<{
    discounts?: {
      discount_code: string;
      discountType: string;
      discount_value: number;
    };
  }> {
    try {
      const cmd = 'doGetDiscountDetail';
      const request = await this.post('ButlAppServlet/user/services', cmd, {
        giftCode: code,
      });
      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }

  async getHistory(page: number): Promise<GetHistoryResponse> {
    try {
      const cmd = 'doGetHistory';
      const request = await this.post('ButlAppServlet/user/services', cmd, {
        page,
      });
      return await this.execute(cmd, request);
    } catch (error) {
      throw error;
    }
  }
}
