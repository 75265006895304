import TripHistory from '../interfaces/entities/TripHistory';
import displayAddressesBaseOnBookingType from '../helpers/displayAddressesBaseOnBookingType';
import StarComponent from './StarComponent';
import renderMoney from '../helpers/renderMoney';
import { useNavigationService } from '../providers/ServicesProvider';

export default function HistoryRow(props: { trip: TripHistory }) {
  const navigationService = useNavigationService();
  const { trip } = props;
  const displayAddresses = displayAddressesBaseOnBookingType(trip.type);
  const onClick = () => {
    navigationService.navigate('/detail-history', { trip });
  };
  return (
    <div
      data-testid={'history-row'}
      className={'flex-column touchable'}
      style={{ gap: 7 }}
      onClick={onClick}
    >
      <div className={'flex-row align-center'} style={{ gap: 11 }}>
        <div
          className={'round main-border center'}
          style={{
            height: 40,
            width: 40,
          }}
        >
          <img
            src={trip.image}
            alt={trip.code}
            className={'contain round'}
            style={{ height: 40, width: 40 }}
          />
        </div>

        <div className={'flex-column flex-1'}>
          <p className={'bold h5'}>{trip.name}</p>
          <p className={'gray-text h5'}>{trip.date}</p>
        </div>

        {trip.rate !== 0 && <StarComponent star={trip.rate} size={'small'} />}
      </div>

      <div className={'flex-column small-border-radius gray-border'}>
        <div
          className={'flex-column border-bottom'}
          style={{ padding: '15px 18px' }}
        >
          <p className={'bold h5'}>Mã: {trip.code}</p>
        </div>
        <div
          className={'flex-column'}
          style={{ padding: '15px 18px', gap: 15 }}
        >
          {displayAddresses.pickup && (
            <div className={'flex-row'} style={{ gap: 15 }}>
              <div
                className={'flex-column center'}
                style={{ height: 15, width: 15 }}
              >
                <div
                  className={'address-point background-green'}
                  style={{ marginTop: 2 }}
                />
              </div>

              <div className={'flex-column'} style={{ gap: 10 }}>
                <p className={'h5'}>Điểm đi</p>

                <div data-testid="pickup" className={'bold h5 full-width'}>
                  {props.trip.pickup}
                </div>
              </div>
            </div>
          )}
          {displayAddresses.dropoff1 && (
            <div className={'flex-row'} style={{ gap: 15 }}>
              <div
                className={'flex-column center'}
                style={{ height: 15, width: 15 }}
              >
                <div
                  className={'address-point background-main'}
                  style={{ marginTop: 2 }}
                />
              </div>

              <div className={'flex-column'} style={{ gap: 10 }}>
                <p className={'h5'}>Điểm đến 1</p>

                <div data-testid="dropoff1" className={'bold h5 full-width'}>
                  {props.trip.dropoff1}
                </div>
              </div>
            </div>
          )}
          {displayAddresses.dropoff2 && trip.dropoff2 && (
            <div className={'flex-row'} style={{ gap: 15 }}>
              <div
                className={'flex-column center'}
                style={{ height: 15, width: 15 }}
              >
                <div
                  className={'address-point background-main-2'}
                  style={{ marginTop: 2 }}
                />
              </div>

              <div className={'flex-column'} style={{ gap: 10 }}>
                <p className={'h5'}>Điểm đến 2</p>

                <div data-testid="dropoff2" className={'bold h5 full-width'}>
                  {props.trip.dropoff2}
                </div>
              </div>
            </div>
          )}
          {displayAddresses.startDay && (
            <div className={'flex-column'} style={{ gap: 10 }}>
              <p className={'h5'}>Ngày đi</p>

              <div data-testid="startday" className={'bold h5 full-width'}>
                {props.trip.startDay}
              </div>
            </div>
          )}
          {displayAddresses.endDay && (
            <div className={'flex-column'} style={{ gap: 10 }}>
              <p className={'h5'}>Ngày đi</p>

              <div data-testid="enddate" className={'bold h5 full-width'}>
                {props.trip.endDay}
              </div>
            </div>
          )}
        </div>

        <div
          className={'flex-column center background-gray'}
          style={{
            padding: '10px 14px',
          }}
        >
          <p className={'bold h3'}>{renderMoney(trip.totalPayment)}</p>
        </div>
      </div>
    </div>
  );
}
