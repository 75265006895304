const Texts = {
  loginScreen: {
    title: 'Đăng nhập',
    phoneNumberInput: 'Số điện thoại',
    passwordInput: 'Mật khẩu',
    loginButton: 'Đăng nhập',
    loginWithOTPButton: 'Đăng nhập với mã OTP',
    loginWithPassword: 'Đăng nhập với mật khẩu',
    getOTPButton: 'Lấy OTP',
    OTPInputLabel: 'Mã đăng nhập',
    registerButton: 'Bạn chưa có tài khoản ? Vui lòng đăng ký',
    errors: {
      nullPhone: 'Vui lòng nhập số điện thoại',
      nullPassword: 'Vui lòng nhập mật khẩu',
      invalidPhone: 'Số điện thoại không hợp lệ',
      nullOTP: 'Vui lòng nhập OTP',
      wrongOTP: 'OTP không đúng vui lòng nhập lại',
      wrongUsernamePassword: 'Sai tài khoản hoặc mật khẩu, vui lòng thử lại',
    },
  },
  updateInfo: {
    title: 'Đăng ký',
    nameInput: 'Họ và tên',
    emailInput: 'Email',
    passwordInput: 'Mật khẩu',
    registerButton: 'Đăng ký',
    errors: {
      nullName: 'Vui lòng nhập họ và tên',
      nullPassword: 'Vui lòng nhập mật khẩu',
      nullEmail: 'Vui lòng nhập email',
      invalidEmail: 'Vui lòng nhập đúng địa chỉ email',
    },
  },
  bottomBar: {
    home: 'Trang chủ',
    history: 'Lịch sử',
    notify: 'Thông báo',
    account: 'Tài khoản',
  },
  loginPopup: {
    title: 'BUTL - Cam kết chất lượng phục vụ',
    loginButton: 'Đăng nhập / Đăng ký',
  },
  bookPage: {
    pickUpTitle: 'Điểm đón',
    dropOffTitle: 'Điểm đi',
    dropOffTitle_1: 'Điểm đi 1',
    dropOffTitle_2: 'Điểm đi 2',
    chooseDropOffTitle: 'Chọn điểm đi',
    chooseDropOffTitle_2: 'Thêm điểm đi',
    payment: 'Thanh toán',
    promotion: 'Ưu đãi',
    note: 'Ghi chú',
  },
  promotion: {
    errors: {
      invalidCode: 'Mã giảm giá không đúng, vui lòng thử lại!',
    },
  },
};

export default Texts;
