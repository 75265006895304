import React from 'react';
import images from '../assets/images';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon: 'phone' | 'key' | 'user';
  onChangeText?: (text: string) => void;
}

export default function IconTextInput(props: TextInputProps) {
  const icon = (() => {
    switch (props.icon) {
      case 'phone':
        return images.phone;
      case 'key':
        return images.key;
      case 'user':
        return images.username;
      default:
        return images.key;
    }
  })();

  const { style, ...rest } = props;
  return (
    <div className={'full-width row-center'} style={{ ...style, gap: 12 }}>
      <img src={icon} style={{ height: 24, width: 24 }} />
      <input
        {...rest}
        className={'input h4 medium border-bottom full-width'}
        style={{
          flex: 1,
          padding: '18px 8px',
        }}
        onChange={event => {
          if (props.onChangeText) props.onChangeText(event.target.value);
        }}
      />
    </div>
  );
}
