import Trip from '../entities/Trip';
import { EventSubscription } from 'fbemitter';

export default interface TripService {
  createTrip(bookID: string): void;
  cancelFindingTrip(): void;
  continueTrip(tripID: string): void;
  onFinishedCreateTrip(cb: (trip: Trip) => void): EventSubscription;
  onCreateTripFailed(cb: () => void): EventSubscription;
  onFinishTrip(cb: () => void): EventSubscription;
  onRejectTrip(cb: () => void): EventSubscription;
}
export const TRIP_EVENTS = {
  ON_FINISH_CREATE_TRIP: 'finish_create_trip',
  ON_CREATE_TRIP_FAILED: 'create_trip_failed',
  ON_FINISH_TRIP: 'finish_trip',
  ON_REJECT_TRIP: 'reject_trip',
  DO_CREATE_TRIP: 'create_trip',
  DO_CANCEL_FINDING_TRIP: 'cancel_finding_trip',
  DO_CONTINUE_TRIP: 'continue_trip',
};
