import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import LoginPage from '../screens/authen/LoginPage';
import UpdateInfoPage from '../screens/authen/UpdateInfoPage';
import HomePage from '../screens/home/HomePage';
import FrontPage from '../screens/home/FrontPage';
import NotifyPage from '../screens/home/NotifyPage';
import AccountPage from '../screens/home/AccountPage';
import HistoryPage from '../screens/home/HistoryPage';
import PickAddressRouterPage from './PickAddressRouterPage';
import MatchPage from '../screens/book/MatchPage';
import TripNavigationPage from './TripNavigationPage';
import RatingNavigationPage from './RatingNavigationPage';
import RejectTripPage from '../screens/book/RejectTripPage';
import BookPage from '../screens/book/BookPage';
import DetailHistoryNavigationPage from './DetailHistoryNavigationPage';
import AuthenticationChecker from '../components/AuthenticationChecker';

export const router = createBrowserRouter([
  {
    element: <HomePage />,
    children: [
      {
        path: '/',
        element: <Navigate to={'/home'} />,
      },
      {
        path: '/home',
        element: <FrontPage />,
      },
      {
        path: '/history',
        element: (
          <AuthenticationChecker>
            <HistoryPage />
          </AuthenticationChecker>
        ),
      },
      {
        path: '/notify',
        element: (
          <AuthenticationChecker>
            <NotifyPage />
          </AuthenticationChecker>
        ),
      },
      {
        path: '/account',
        element: (
          <AuthenticationChecker>
            <AccountPage />
          </AuthenticationChecker>
        ),
      },
    ],
  },
  {
    path: '/book',
    element: (
      <AuthenticationChecker>
        <BookPage />
      </AuthenticationChecker>
    ),
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/update-info',
    element: <UpdateInfoPage />,
  },
  {
    path: '/pick-address',
    element: (
      <AuthenticationChecker>
        <PickAddressRouterPage />
      </AuthenticationChecker>
    ),
  },
  {
    path: '/find-driver',
    element: (
      <AuthenticationChecker>
        <MatchPage />
      </AuthenticationChecker>
    ),
  },
  {
    path: '/trip',
    element: (
      <AuthenticationChecker>
        <TripNavigationPage />
      </AuthenticationChecker>
    ),
  },
  {
    path: '/rating',
    element: (
      <AuthenticationChecker>
        <RatingNavigationPage />
      </AuthenticationChecker>
    ),
  },
  {
    path: '/reject',
    element: (
      <AuthenticationChecker>
        <RejectTripPage />
      </AuthenticationChecker>
    ),
  },
  {
    path: '/detail-history',
    element: (
      <AuthenticationChecker>
        <DetailHistoryNavigationPage />
      </AuthenticationChecker>
    ),
  },
]);

export const Navigation = () => {
  return <RouterProvider router={router} />;
};
