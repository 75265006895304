import Address from '../interfaces/entities/Address';
import { ServiceType } from '../interfaces/entities/ServiceType';

export default function hasEnoughDataForBooking(
  pickup: Address | null,
  dropOff_1: Address | null,
  serviceType: ServiceType | null,
) {
  if (serviceType === null) {
    return false;
  }

  const type = serviceType.type;

  if (
    type === '2_ADDRESS' ||
    type === '2_ADDRESS_2_DAY' ||
    type === '2_ADDRESS_1_DAY'
  ) {
    return pickup !== null && dropOff_1 !== null;
  }

  return pickup !== null;
}
